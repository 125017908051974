.system-deduction-wrapper tbody {
    max-height: 52vh;
}

.system-deduction-wrapper th:nth-child(1),
.system-deduction-wrapper td:nth-child(1),
.system-deduction-wrapper th:nth-child(2),
.system-deduction-wrapper td:nth-child(2) {
    padding: 0.5%;
    text-align: left !important;
    padding-left: 0 !important;
    width: 30%;
}

.system-deduction-wrapper th:nth-child(3),
.system-deduction-wrapper td:nth-child(3) {
    padding: 0.5%;
    display: flex;
    justify-content: end;
    padding-left: 0 !important;
}