.redFileContainer {
    background-color: #fff6f6;
    padding: 1%;
    color: #ff726f !important;
    border-radius: 0.5em;
    display: flex;
    align-items: center;
}

.redIconContainer {
    background-color: #ffcccb;
    color: #ff726f;
    border-radius: 0.3em;
}

.redMenuContainer {
    background-color: #ffe8e6;
    color: #ff726f;
    border-radius: 0.3em;
    padding: 1.5%;
}

.greenFileContainer {
    background-color: #f5fcf3;
    padding: 1%;
    color: #009918 !important;
    border-radius: 0.5em;
    display: flex;
    align-items: center;
}

.greenIconContainer {
    background-color: #c2ebb8;
    color: #009918;
    border-radius: 0.3em;
}

.greenMenuContainer {
    background-color: #e1f5db;
    color: #009918;
    border-radius: 0.3em;
    padding: 1.5%;
}

.pinkFileContainer {
    background-color: #fff7ff;
    padding: 1%;
    color: #f654ff !important;
    border-radius: 0.5em;
    display: flex;
    align-items: center;
}

.pinkIconContainer {
    background-color: #ffccff;
    color: #f654ff;
    border-radius: 0.3em;
}

.pinkMenuContainer {
    background-color: #ffe6ff;
    color: #f654ff;
    border-radius: 0.3em;
    padding: 1.5%;
}

.purpleFileContainer {
    background-color: #f6f4fb;
    padding: 1%;
    color: #7f4fff !important;
    border-radius: 0.5em;
    display: flex;
    align-items: center;
}

.purpleIconContainer {
    background-color: #eae0ff;
    color: #7f4fff;
    border-radius: 0.3em;
}

.purpleMenuContainer {
    background-color: #ebe2ff;
    color: #7f4fff;
    border-radius: 0.3em;
    padding: 1.5%;
}

.file-name {
    font-weight: bold;
}

.file-list-container {
    max-height: 45%;
    overflow-y: scroll;
}

.no-vertical-scroll {
    overflow-y: hidden !important;
    overflow: hidden !important;
}

.limit-container-height {
    max-height: 97vh !important;
}
.smaller-font {
    font-size: 50% !important;
}
.company-wrapper th:nth-child(1),
.company-wrapper td:nth-child(1),
.company-wrapper th:nth-child(2),
.company-wrapper td:nth-child(2) {
    padding: 0.5%;
    padding-left: 0 !important;
}

.company-wrapper th:nth-child(3),
.company-wrapper td:nth-child(3) {
    padding: 0.5%;
    display: flex;
    justify-content: end;
    padding-left: 0 !important;
}