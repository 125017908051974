.wfa-wrapper th:nth-child(1),
.wfa-wrapper td:nth-child(1) {
    padding: 0.5%;
    padding-left: 0 !important;
    /* width: 20%; */
}

.wfa-wrapper th:nth-child(2),
.wfa-wrapper td:nth-child(2) {
    padding-left: 0;
    width: 10%;
}

.wfa-wrapper th:nth-child(3),
.wfa-wrapper td:nth-child(3) {
    padding-left: 0;
}

.wfa-wrapper th:nth-child(4),
.wfa-wrapper td:nth-child(4) {
    padding-left: 0;
    width: 10%;
}

.wfa-wrapper th:nth-child(4),
.wfa-wrapper td:nth-child(4) {
    padding-left: 0;
}