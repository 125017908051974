.employment-status-wrapper tbody {
    max-height: 52vh;
}

.employment-status-wrapper td:nth-child(1),
.employment-status-wrapper th:nth-child(1) {
    text-align: left;
    padding: 0.5%;
        padding-left: 0 !important;
}

.header p {
    margin-bottom: 0 !important;
}

/* contracts */
.contracts .nav-link {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 18px;
    color: black;
    text-transform: uppercase;
}

.contracts .nav-link.active, .contracts .show>.nav-link {
    border-bottom: 2px solid !important;
    border-top: none;
    border-left: none;
    border-right: none;
    color: black !important;
    background-color: transparent !important;
    border-color: none none black none;
}

.contracts .nav-link:focus, .contracts .nav-link:hover {
    color: black;
}

.comm-header-status {
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.9vw;
    line-height: 46px;
    letter-spacing: 0.02em;
    /* color: #000000; */
        color: var(--fourth-color);
        /* margin-left: 1em; */
    margin-top: .0em;
}

.export-button-status {
    background-color: var(--primary-color);
    border: none;
    border-radius: 10px;
    color: white;
    padding: 8%;
    border-radius: 500em;
    width: 150% !important;
    margin-left: -5%;
    /* margin-right: 100% !important; */
    /* border-radius: 999em 999em 999em 999em */
}

.search-status{
    margin-top: 1%;
}

.dropdown-btn-status{
    background-color: var(--primary-color);
    border: none;
    border-radius: 10px;
    color: white;
    width: 6vw;
    padding: 1%;
    display: flex;
    justify-content:end !important;

    margin-left: 83% !important;
}

@media (min-width: 280px) and (max-width: 359px) {
    .comm-header-status {
        font-size: 6vw;
    }
    .export-button-status {
        margin-left: -7% !important;
        width: 172% !important;
    }
    .search-status{
        margin-top: 17%;
        margin-left: 2%;
    }
    .dropdown-btn-status {
        margin-left: 0 !important;
        width: 25vw;
    }
}

@media (min-width: 360px) and (max-width: 374px) {
    .comm-header-status {
        font-size: 3.3vw;
    }
    .export-button-status {
        padding: 2px;
        width: 230% !important;
        margin-left: -6%;
        margin-top: 7%;
        font-size: 12px;
        text-align: center;
    }
    .search-status{
        margin-top: 0;
    }
    .dropdown-btn-status {
        margin-left: 0 !important;
        width: 25vw;
    }
}

@media (min-width: 375px) and (max-width: 389px) {
    .comm-header-status {
        font-size: 3.5vw;
    }
    .export-button-status {
        padding: 2px;
        width: 210% !important;
        margin-left: 2%;
        margin-top: 7%;
        font-size: 12px;
        text-align: center;
    }
    .search-status{
        margin-top: 0;
    }
    .dropdown-btn-status {
        margin-left: 0 !important;
        width: 25vw;
    }
}

/* IPhone 12 Pro */
@media (min-width: 390px) and (max-width: 392px) and (-webkit-device-pixel-ratio : 3)and (orientation : portrait){
    .comm-header-status {
        font-size: 3.5vw;
    }
    .export-button-status {
        padding: 2px;
        width: 190% !important;
        margin-left: 12%;
        margin-top: 3%;
        font-size: 12px;
        text-align: center;
    }
    .search-status{
        margin-top: 0;
    }
    .dropdown-btn-status {
        margin-left: 0 !important;
        width: 25vw;
    }
}

@media (min-width: 393px) and (max-width: 399px){
    .comm-header-status {
        font-size: 3.5vw;
    }
    .export-button-status {
        padding: 2px;
        width: 190% !important;
        margin-left: 12%;
        margin-top: 3%;
        font-size: 12px;
        text-align: center;
    }
    .search-status{
        margin-top: 0;
    }
    .dropdown-btn-status {
        margin-left: 0 !important;
        width: 25vw;
    }
}

/* IPhone XR */
@media (min-width: 414px) and (max-width: 420px) and (-webkit-device-pixel-ratio : 2)and (orientation : portrait) {
    .comm-header-status {
        font-size: 3.5vw;
    }
    .export-button-status {
        padding: 2px;
        width: 170% !important;
        margin-left: 21%;
        margin-top: 2%;
        font-size: 12px;
        text-align: center;
    }
    .search-status{
        margin-top: 0;
    }
    .dropdown-btn-status {
        margin-left: 0 !important;
        width: 25vw;
    }
}

/* IPhone 11 Pro Max */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) { 
    .comm-header-status {
        font-size: 3.5vw;
    }
    .export-button-status {
        padding: 2px;
        width: 170% !important;
        margin-left: 21%;
        margin-top: 2%;
        font-size: 12px;
        text-align: center;
    }
    .search-status{
        margin-top: 0;
    }
    .dropdown-btn-status {
        margin-left: 0 !important;
        width: 25vw;
    }
}

@media (min-width: 400px) and (max-width: 412px){
    .comm-header-status {
        font-size: 3.5vw;
    }
    .export-button-status {
        padding: 2px;
        width: 170% !important;
        margin-left: 21%;
        margin-top: 2%;
        font-size: 12px;
        text-align: center;
    }
    .search-status{
        margin-top: 0;
    }
    .dropdown-btn-status {
        margin-left: 0 !important;
        width: 25vw;
    }
}

@media (min-width: 540px) and (max-width: 767px) { 
    .comm-header-status {
        font-size: 3.5vw;
    }
    .export-button-status {
        padding: 2px;
        width: 120% !important;
        margin-left: 36%;
        margin-top: 2%;
        font-size: 12px;
        text-align: center;
    }
    .search-status{
        margin-top: 0;
    }
    .dropdown-btn-status {
        margin-left: 0 !important;
        width: 15vw;
    }
}

@media (min-width: 768px) and (max-width: 819px) { 
    .comm-header-status {
        font-size: 4vw;
    }
    .export-button-status {
        margin-left: -4%;
    }
    .dropdown-btn-status {
        margin-left: 78% !important;
        width: 10vw;
    }
}

@media (min-width: 820px) and (max-width: 911px){
    .comm-header-status {
        font-size: 4vw;
    }
    .export-button-status {
        margin-left: -4%;
    }
    .dropdown-btn-status {
        margin-left: 78% !important;
        width: 10vw;
    }
}

@media (min-width: 912px) and (max-width: 1023px) { 
    .comm-header-status {
        font-size: 4vw;
    }
    .export-button-status {
        margin-left: -6%;
    }
    .dropdown-btn-status {
        margin-left: 78% !important;
        width: 10vw;
    }
}

@media (min-width:1024px) and (max-width: 1279px){
    .export-button-status {
        margin-left: -4%;
    }
    .dropdown-btn-status {
        margin-left: 78% !important;
        width: 10vw;
    }
}

@media (min-width:1280px) and (max-width: 1290px){
    .export-button-status {
        margin-left: -4%;
    }
}