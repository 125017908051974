.shift-wrapper tbody {
    max-height: 52vh;
}

.shift-wrapper td:nth-child(1),
.shift-wrapper th:nth-child(1) {
    text-align: left;
        padding: 0.5%;
            padding-left: 0 !important;
            width: 30%;
}

.shift-wrapper td:nth-child(2),
.shift-wrapper th:nth-child(2),
.shift-wrapper td:nth-child(3),
.shift-wrapper th:nth-child(3),
.shift-wrapper td:nth-child(4),
.shift-wrapper th:nth-child(4),
.shift-wrapper td:nth-child(5),
.shift-wrapper th:nth-child(5) {
    text-align: left;
    padding: 0.5%;
        padding-left: 0 !important;
}


.comm-header-shift {
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.9vw;
    line-height: 46px;
    letter-spacing: 0.02em;
    /* color: #000000; */
        color: var(--fourth-color);
        /* margin-left: 1em; */
    margin-top: .0em;
}

.export-button-shift {
    background-color: var(--primary-color);
    border: none;
    border-radius: 10px;
    color: white;
    padding: 8%;
    border-radius: 500em;
    width: 150% !important;
    margin-left: 45%;
    /* border-radius: 999em 999em 999em 999em */
}

.dropdown-btn-shift {
    background-color: var(--primary-color);
    border: none;
    border-radius: 10px;
    color: white;
    width: 6vw;
    padding: 1%;
    display: flex;
    justify-content:end !important;

    margin-left: 49% !important;
}

@media (min-width: 280px) and (max-width: 359px) {
    .comm-header-shift {
        font-size: 13.5vw;
    }
    .export-button-shift {
        margin-left: -64% !important;
        width: 260% !important;
        margin-top: 4%;
    }
    .search-shift{
        margin-top: 0;
        margin-left: 2%;
    }

    .input-shift1{
        width: 55% !important;
    }

    .input-shift2{
        width: 55% !important;
    }

    .shift-wrapper td:nth-child(1) {
        text-align: center;
        padding-left: 39vw;
    }
    
    .shift-wrapper td:nth-child(2) {
        text-align: center;
        padding-left: 30vw;
    }
    
    .shift-wrapper td:nth-child(3) {
        text-align: center;
        padding-left: 30vw;
    }
    
    .shift-wrapper td:nth-child(4) {
        text-align: center;
        padding-left: 19vw;
    }
    
    .shift-wrapper td:nth-child(5) {
        text-align: center;
        padding-left: 25vw;
    }
    .dropdown-btn-shift {
        margin-left: 0 !important;
        width: 25vw;
    }
}

@media (min-width: 360px) and (max-width: 374px) {
    .comm-header-shift {
        font-size: 6vw;
    }
    .export-button-shift {
        padding: 2px;
        width: 170% !important;
        margin-left: 57%;
        margin-top: 8%;
        font-size: 12px;
        text-align: center;
    }
    .input-shift2{
        width: 58% !important;
    }

    .shift-wrapper td:nth-child(1) {
        text-align: center;
        padding-left: 12vw;
    }
    
    .shift-wrapper td:nth-child(2) {
        text-align: center;
        padding-left: 1.5vw;
    }
    
    .shift-wrapper td:nth-child(3) {
        text-align: center;
        padding-left: 1.5vw;
    }
    
    .shift-wrapper td:nth-child(4) {
        text-align: center;
        padding-left: 0vw;
    }
    
    .shift-wrapper td:nth-child(5) {
        text-align: center;
        padding-left: 2vw;
    }
    .dropdown-btn-shift {
        margin-left: 0 !important;
        width: 25vw;
    }
}

@media (min-width: 375px) and (max-width: 389px) {
    .comm-header-shift {
        font-size: 6vw;
    }
    .export-button-shift {
        padding: 2px;
        width: 170% !important;
        margin-left: 57%;
        margin-top: 4%;
        font-size: 10px;
        text-align: center;
    }
    .input-shift2{
        width: 58% !important;
    }

    .shift-wrapper td:nth-child(1) {
        text-align: center;
        padding-left: 6vw;
    }
    
    .shift-wrapper td:nth-child(2) {
        text-align: center;
        padding-left: 1.5vw;
    }
    
    .shift-wrapper td:nth-child(3) {
        text-align: center;
        padding-left: 1.5vw;
    }
    
    .shift-wrapper td:nth-child(4) {
        text-align: center;
        padding-left: 0vw;
    }
    
    .shift-wrapper td:nth-child(5) {
        text-align: center;
        padding-left: 2vw;
    }
    .dropdown-btn-shift {
        margin-left: 0 !important;
        width: 25vw;
    }
}

/* IPhone 12 Pro */
@media (min-width: 390px) and (max-width: 392px) and (-webkit-device-pixel-ratio : 3)and (orientation : portrait){
    .comm-header-shift {
        font-size: 6vw;
    }
    .export-button-shift {
        padding: 2px;
        width: 160% !important;
        margin-left: 53%;
        margin-top: 8%;
        font-size: 12px;
        text-align: center;
    }
    .input-shift2{
        width: 58% !important;
    }
    .shift-wrapper td:nth-child(1) {
        text-align: center;
        padding-left: 3vw;
    }
    
    .shift-wrapper td:nth-child(2) {
        text-align: center;
        padding-left: 1.5vw;
    }
    
    .shift-wrapper td:nth-child(3) {
        text-align: center;
        padding-left: 1.5vw;
    }
    
    .shift-wrapper td:nth-child(4) {
        text-align: center;
        padding-left: 0vw;
    }
    
    .shift-wrapper td:nth-child(5) {
        text-align: center;
        padding-left: 2vw;
    }
    .dropdown-btn-shift {
        margin-left: 0 !important;
        width: 25vw;
    }
}

@media (min-width: 393px) and (max-width: 399px){
    .comm-header-shift {
        font-size: 6vw;
    }
    .export-button-shift {
        padding: 2px;
        width: 160% !important;
        margin-left: 53%;
        margin-top: 5%;
        font-size: 12px;
        text-align: center;
    }
    .input-shift2{
        width: 58% !important;
    }
    .shift-wrapper td:nth-child(1) {
        text-align: center;
        padding-left: 3vw;
    }
    
    .shift-wrapper td:nth-child(2) {
        text-align: center;
        padding-left: 1.5vw;
    }
    
    .shift-wrapper td:nth-child(3) {
        text-align: center;
        padding-left: 1.5vw;
    }
    
    .shift-wrapper td:nth-child(4) {
        text-align: center;
        padding-left: 0vw;
    }
    
    .shift-wrapper td:nth-child(5) {
        text-align: center;
        padding-left: 2vw;
    }
    .dropdown-btn-shift {
        margin-left: 0 !important;
        width: 25vw;
    }
}

/* IPhone XR */
@media (min-width: 414px) and (max-width: 420px) and (-webkit-device-pixel-ratio : 2)and (orientation : portrait) {
    .comm-header-shift {
        font-size: 6vw;
    }
    .export-button-shift {
        padding: 2px;
        width: 160% !important;
        margin-left: 51%;
        margin-top: 6%;
        font-size: 12px;
        text-align: center;
    }
    .input-shift2{
        width: 58% !important;
    }
    .shift-wrapper td:nth-child(1) {
        text-align: center;
        padding-left: 3vw;
    }
    
    .shift-wrapper td:nth-child(2) {
        text-align: center;
        padding-left: 1.5vw;
    }
    
    .shift-wrapper td:nth-child(3) {
        text-align: center;
        padding-left: 1.5vw;
    }
    
    .shift-wrapper td:nth-child(4) {
        text-align: center;
        padding-left: 0vw;
    }
    
    .shift-wrapper td:nth-child(5) {
        text-align: center;
        padding-left: 2vw;
    }
    .dropdown-btn-shift {
        margin-left: 0 !important;
        width: 25vw;
    }
}

/* IPhone 11 Pro Max */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) { 
    .comm-header-shift {
        font-size: 6vw;
    }
    .export-button-shift {
        padding: 2px;
        width: 160% !important;
        margin-left: 51%;
        margin-top: 6%;
        font-size: 12px;
        text-align: center;
    }
    .input-shift2{
        width: 58% !important;
    }
    .shift-wrapper td:nth-child(1) {
        text-align: center;
        padding-left: 3vw;
    }
    
    .shift-wrapper td:nth-child(2) {
        text-align: center;
        padding-left: 1.5vw;
    }
    
    .shift-wrapper td:nth-child(3) {
        text-align: center;
        padding-left: 1.5vw;
    }
    
    .shift-wrapper td:nth-child(4) {
        text-align: center;
        padding-left: 0vw;
    }
    
    .shift-wrapper td:nth-child(5) {
        text-align: center;
        padding-left: 2vw;
    }
    .dropdown-btn-shift {
        margin-left: 0 !important;
        width: 25vw;
    }
}

@media (min-width: 400px) and (max-width: 412px){
    .comm-header-shift {
        font-size: 6vw;
    }
    .export-button-shift {
        padding: 2px;
        width: 160% !important;
        margin-left: 51%;
        margin-top: 6%;
        font-size: 12px;
        text-align: center;
    }
    .input-shift2{
        width: 58% !important;
    }
    .shift-wrapper td:nth-child(1) {
        text-align: center;
        padding-left: 3vw;
    }
    
    .shift-wrapper td:nth-child(2) {
        text-align: center;
        padding-left: 1.5vw;
    }
    
    .shift-wrapper td:nth-child(3) {
        text-align: center;
        padding-left: 1.5vw;
    }
    
    .shift-wrapper td:nth-child(4) {
        text-align: center;
        padding-left: 0vw;
    }
    
    .shift-wrapper td:nth-child(5) {
        text-align: center;
        padding-left: 2vw;
    }
    .dropdown-btn-shift {
        margin-left: 0 !important;
        width: 25vw;
    }
}

@media (min-width: 540px) and (max-width: 767px) { 
    .comm-header-shift {
        font-size: 6vw;
    }
    .export-button-shift {
        padding: 2px;
        width: 135% !important;
        margin-left: 75%;
        margin-top: 12%;
        font-size: 12px;
        text-align: center;
    }
    .input-shift2{
        width: 40% !important;
    }
    .shift-wrapper td:nth-child(1) {
        text-align: left;
        padding-left: 25vw;
    }
    
    .shift-wrapper td:nth-child(2) {
        text-align: left;
        padding-left: 28vw;
    }
    
    .shift-wrapper td:nth-child(3) {
        text-align: left;
        padding-left: 28vw;
    }
    
    .shift-wrapper td:nth-child(4) {
        text-align: left;
        padding-left: 30vw;
    }
    
    .shift-wrapper td:nth-child(5) {
        text-align: left;
        padding-left: 28vw;
    }
    .dropdown-btn-shift {
        margin-left: 0 !important;
        width: 25vw;
    }
}

@media (min-width: 768px) and (max-width: 819px) { 
    .comm-header-shift {
        font-size: 5vw;
    }
    .export-button-shift {
        margin-left: 43%;
    }
    .input-shift2{
        width: 30% !important;
    }
    .dropdown-btn-shift{
        margin-left: 30% !important;
        width: 10vw;
    }
}

@media (min-width: 820px) and (max-width: 911px){
    .comm-header-shift{
        font-size: 5vw;
    }
    .export-button-shift {
        margin-left: 43%;
    }
    .input-shift2{
        width: 30% !important;
    }
    .dropdown-btn-shift{
        margin-left: 30% !important;
        width: 10vw;
    }
}

@media (min-width: 912px) and (max-width: 1023px) { 
    .comm-header-shift{
        font-size: 5vw;
    }
    .export-button-shift {
        margin-left: 45%;
    }
    .input-shift2{
        width: 20% !important;
    }
    .dropdown-btn-shift{
        margin-left: 30% !important;
        width: 10vw;
    }
}

@media (min-width:1024px) and (max-width: 1279px){
    .export-button-shift {
        margin-left: 43%;
    }
    .dropdown-btn-shift{
        margin-left: 30% !important;
        width: 10vw;
    }
}

@media (min-width:1280px) and (max-width: 1290px){
    .export-button-shift {
        margin-left: 43%;
    }
}