/* CSS Here are just files that are common throughout all components */

:root {
    /* --primary-color: #3c3c43; */
        --primary-color: #5ac8e1;
        /* --secondary-color: #d7f0ed; */
        --secondary-color: #d3f3fa;
    --tertiary-color: #3d9889;
    --fourth-color: #3c3c43;
    --fifth-color: #b9eeff;
    --text-color: #3c3c43;
}
/* Common page style */
.comm-bg {
    background-color: var(--secondary-color);
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
}

.comm-page-container {
    /* height: 100vh; */
    width: 79%;
    float: right;
    /* overflow-y: hidden !important; */
    overflow-x: hidden;
    margin-top: -1.50%;
}

.comm-controls {
    display: flex;
    justify-content: flex-end;
    /* background: #9db9ff; */
    background: #80C8E3;
    width: 100%;
    height: 3em;
    margin-top: 1.5em;
    float: top
}

.payroll-filter {
    height: 4em;
}

.comm-header {
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.9vw;
    line-height: 46px;
    letter-spacing: 0.02em;
    /* color: #000000; */
        color: var(--fourth-color);
        /* margin-left: 1em; */
    margin-top: .8em;
}

.comm-subheader {
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.8vw;
    letter-spacing: 0.02em;
    color: #000000;
    margin: .5em 1em .5em 1em;
}

.comm-subheader img, .comm-subheader-img {
    float: inline-start;
    margin-right: .5em;
    padding-bottom: 0;
    margin-bottom: 0;
}

.comm-btn {
    background: #E8E8E8;
    border: 2px solid #008ec2;
    box-sizing: border-box;
    border-radius: 5px;
    height: 55%;
    width: fit-content;
    padding-left: 1.5%;
    padding-right: 1.5%;
    margin-right: 2%;
    margin-top: 1%;
    
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.03vw;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #008ec2;
}

.comm-btn:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: all .2s;
}

.comm-btn:hover {
    background: #60C7EC;
    color: white;
    cursor: pointer;
}

.back-btn {
    justify-content: flex-start;
    padding-left: 2rem;
}

.cont-section-novertpad {
    padding: 0 4em 0 4em;
}

/* View Details per row in tables */

.view-deets {
    color: black !important;
    text-decoration: none;
    border-radius: .2rem;
}

.view-deets .table-temp:hover {
    cursor: pointer;
    background-color: #9dd5e962;
}

.table-temp {
    cursor: default;
    /* white-space: pre-wrap; */
        overflow-wrap: break-word;
}

/* Edit and Delete Icons */
.table-temp .MuiSvgIcon-fontSizeInherit {
    font-size: 1rem !important;
}

.dual {
    width: 45px;
    vertical-align: middle;
}

.single {
    width: 30px;
    vertical-align: middle;
}

/* EBR TABLE COMMON STYLES */

.ebr-table-wrapper {
    background-color: white;
        padding: 3%;
        border-radius: 2em;
        box-shadow: 8px 8px 8px -6px rgba(0, 0, 0, 0.3);
        max-height: 75vh !important;
        overflow-y: auto;
        overflow-x: hidden;
}

.table-text {
    font-size: 8px !important;
}
.ebr-table-wrapper .ebr-table  thead {
    border-top: 2px solid var(--primary-color);
    border-bottom: 2px solid var(--primary-color);
    text-transform: uppercase;
    font-size: 120% !important;
}

.ebr-table-wrapper .ebr-table thead, .ebr-table-wrapper .ebr-table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    vertical-align: middle;
    font-size: 180%;
}

.ebr-table-wrapper .ebr-table {
    border-bottom: 1px solid var(--fourth-color);
}

.ebr-table-wrapper .ebr-table tbody {
    display: initial;
    overflow-y: scroll;
}

.align-col-left {
    display: flex !important;
    justify-content: left !important;
    padding: 0 !important;
}

/* CUSTOM SCROLLBAR */

::-webkit-scrollbar {
    width: 5px;
    border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #939393;
    border-radius: 2px; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #7E7C7C;
    border-radius: 2px;
}
.dateFilter-container {
    /* width: 10% !important; */
    overflow-x: hidden !important;
    justify-content: end;
}

.dateFilter {
    margin-top: 0 !important;
    font-size: 80%;
    font-family: monospace;
    justify-content: left;
}

.date-filter-title{
    margin-top: 0 !important;
    margin-bottom: 0.5rem !important;
    font-weight: 500 !important;
    line-height: 1.2 !important;
    font-size: 1rem !important;
}

.datepicker {
    /* width: 225%; */
    /* width: 100%; */
    color: gray;
}
.filter-container {
    background-color: white !important;
    border-radius: 10px !important;
    z-index: 100;
    color: var(--fourth-color);
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0px 8px 8px -6px rgba(0, 0, 0, 0.3);
    padding-top: 2px;
    /* padding-bottom: 2px; */
    align-content: space-between;
}
.dropdown-btn {
    background-color: var(--primary-color);
    border: none;
    border-radius: 10px;
    color: white;
    width: 6vw;
    padding: 1%;
    display: flex;
    justify-content:end !important;

}

.btn-close{
    position: absolute;
    top: 1%;
    right: 1%;

} 

.btn-close-employee {
    position: absolute;
    top: 1%;
    right: 1%;

    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: #000;
    /* background: transparent url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e) center/1em auto no-repeat; */
    border: 0;
    border-radius: 0.25rem;
    opacity: 0.5;

}

.save-button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    width: 8vw;
    border-radius: 10px;
}

.cancel-button {
    color: var(--primary-color);
    width: 8vw;
    border-style: solid;
    border-radius: 10px;
    border-color: var(--primary-color);
    background-color: white;
}

.delete-button {
    color: white;
    width: 8vw;
    border-style: solid;
    border-radius: 10px;
    border-color: rgba(223, 86, 86, 0.951);
    background-color: rgba(223, 86, 86, 0.951);
}
.delete-button-employee {
    color: white;
    width: 8vw;
    border-style: solid;
    border-radius: 10px;
    border-color: rgba(223, 86, 86, 0.951);
    background-color: rgba(223, 86, 86, 0.951);
}
.input-field {
    border-style: solid;
    border-radius: 10px;
    border-color: var(--primary-color);
}

.red {
    color: red;
}

.modal-header {
    color: var(--primary-color);
    font-size: 2vw;
    font-weight: bold;
}

.modal-line {
    color: var(--primary-color);
    height: 20px;
    opacity: 100;
}
.warning {
    font-size: 3vw;
    color: rgba(223, 86, 86, 0.951);
    font-weight: 900 !important;
}

.warning-icon {
    transform: scale(4);
}
.white {
    color: white;
}

.form-check-input {
    width: 1em !important;
    height: 1em;
    border-color: var(--primary-color) !important;
}

/* CONTAINERS */
.ebr-container-wrapper {
    background-color: white;
    padding: 1%;
    border-radius: 2em;
    box-shadow: 8px 8px 8px -6px rgba(0, 0, 0, 0.3);
    max-height: 100vh;
}

/* Message */
.message-container {
    background-color: white;
    padding: 1%;
    border-radius: 2em;
    box-shadow: 8px 8px 8px -6px rgba(0, 0, 0, 0.3);
    max-height: 75vh !important;
    margin-top: 1%;
    max-width: 60vw !important;
}

.message-title{
    width: 300px;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 37px;
    align-items: center;
    letter-spacing: 0.02em;
    color: #000000;
}

.message{
    position: relative;
    width: 900px;
    height: 23px;
    left: 2px;
    top: 5px;
    text-align: justify;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height */
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #000000;
}
.export-btn-filter {
    background-color: var(--primary-color);
    border: none;
    border-radius: 10px;
    color: white;
    border-radius: 500em;
    width: max-content;
    margin-left: 0;
    margin-top: 5% !important;
    padding: 2px 6px 2px 6px;
}

/* REACT QUILL ADDED CSS*/
div.ql-toolbar.ql-snow,
div.ql-container.ql-snow {
    position: relative;
    /* flex: 0.8 0.8 auto; */
    margin-left: 1.5%;
    margin-right: 1.5%;
    max-width: 97% !important;
}

.ql-toolbar.ql-snow {
    border: 1.5px solid var(--primary-color) !important;
    border-radius: 10px 10px 0px 0px;
}

.ql-container.ql-snow {
    border: 1px solid var(--primary-color) !important;
    border-radius: 0px 0px 10px 10px;
}

.ql-container {
    box-sizing: border-box;
    font-family: 'Source Sans Pro';
}

.ql-align-center {
    text-align: center !important;
}

.ql-align-right {
    text-align: right !important;
}

.ql-size-small {
    font-size: 50% !important;
}

.ql-size-large {
    font-size: 150% !important;
}

.ql-size-huge {
    font-size: 200% !important;
}

/* REACT QUILL END*/
@media (min-width: 280px) and (max-width: 359px) {
    .ebr-table-wrapper {
        border-radius: 0.5em;
    }
    .save-button {
        width: 20vw;
    }
    .cancel-button {
        width: 24vw;
    }
    .delete-button {
        width: 22vw;
    }
    .delete-button-employee {
        font-size: 7px;
        width: 13.5vw;
    }
    .modal-header {
        font-size: 6vw;
    }
    .comm-page-container {
        width: 95%;
    }
    /* Employee */
    .filter-container {
        margin-right: 5% !important;
        margin-bottom: 4% !important;
    }
    /* Message */
    .message-container {
        margin-bottom: 4%;
        width: 94% !important;
        border-radius: 0.5em;
    }
    .message-title{
        font-size: 20px;
    }
    /* Filter */
    .dateFilter{
        min-width: min-content !important;
        margin-bottom: 2%;
    }
    .date-filter-title{
        font-size: 0.5rem !important;
    }
    .export-btn-filter {
        margin-top: 33% !important;
        margin-left: 90% !important;
        margin-bottom: 2%;
        padding: 2px 6px 2px 6px;
    }

}

@media (min-width: 360px) and (max-width: 374px) {
    .ebr-table-wrapper {
        border-radius: 0.5em;
    }
    .save-button {
        width: 18vw;
    }
    .cancel-button {
        width: 18vw;
    }
    .delete-button {
        width: 18vw;
    }
    .delete-button-employee {
        width: 15vw;
        font-size: 10px;
    }
    .modal-header {
        font-size: 5vw;
    }
    .comm-page-container {
        width: 95%;
    }
    /* Employee */
    .filter-container {
        margin-right: 5% !important;
        margin-bottom: 3% !important;
    }
   /* Message */
    .message-container {
        margin-bottom: 4%;
        width: 94% !important;
        border-radius: 0.5em;
    }
    .message-title{
        font-size: 20px;
    }
    /* Filter */
    .dateFilter{
        min-width: min-content !important;
        margin-bottom: 2%;
    }
    .date-filter-title{
        font-size: 0.7rem !important;
    }
    .export-btn-filter {
        margin-top: 23.5% !important;
        margin-left: 57% !important;
        margin-bottom: 2%;
        padding: 2px 6px 2px 6px;
    }
}

@media (min-width: 375px) and (max-width: 389px) {
    .ebr-table-wrapper {
        border-radius: 0.5em;
    }
    .save-button {
        width: 18vw;
    }
    .cancel-button {
        width: 18vw;
    }
    .delete-button {
        width: 18vw;
    }
    .delete-button-employee {
        width: 15vw;
        font-size: 10px;
    }
    .modal-header {
        font-size: 5vw;
    }
    .comm-page-container {
        width: 95%;
    }
    /* Employee */
    .filter-container {
        margin-right: 5% !important;
        margin-bottom: 2% !important;
    }
    /* Message */
    .message-container {
        margin-bottom: 5%;
        width: 94% !important;
        border-radius: 0.5em;
    }
    .message-title{
        font-size: 20px;
    }
    /* Filter */
    .dateFilter{
        min-width: min-content !important;
        margin-bottom: 2%;
    }
    .date-filter-title{
        font-size: 0.7rem !important;
    }
    .export-btn-filter {
        margin-top: 22% !important;
        margin-left: 55% !important;
        margin-bottom: 2%;
        padding: 2px 6px 2px 6px;
    }
}

/* IPhone 12 Pro */
@media (min-width: 390px) and (max-width: 392px) and (-webkit-device-pixel-ratio : 3)and (orientation : portrait){
    .ebr-table-wrapper {
        border-radius: 0.5em;
    }
    .save-button {
        width: 18vw;
    }
    .cancel-button {
        width: 18vw;
    }
    .delete-button {
        width: 18vw;
    }
    .delete-button-employee {
        width: 15vw;
        font-size: 12px;
    }
    .modal-header {
        font-size: 5vw;
    }
    .comm-page-container {
        width: 95%;
    }

    /* Employee */
    .filter-container {
        margin-right: 5% !important;
        margin-bottom: 2% !important;
    }
    /* Message */
    .message-container {
        margin-bottom: 5%;
        width: 94% !important;
        border-radius: 0.5em;
    }
    .message-title{
        font-size: 20px;
    }
    /* Filter */
    .dateFilter{
        min-width: min-content !important;
        margin-bottom: 2%;
    }
    .date-filter-title{
        font-size: 0.8rem !important;
    }
    .export-btn-filter {
        margin-top: 21% !important;
        margin-left: 50% !important;
        margin-bottom: 2%;
        padding: 2px 6px 2px 6px;
    }
}

@media (min-width: 393px) and (max-width: 399px){
    .ebr-table-wrapper {
        border-radius: 0.5em;
    }
    .save-button {
        width: 18vw;
    }
    .cancel-button {
        width: 18vw;
    }
    .delete-button {
        width: 18vw;
    }
    .delete-button-employee {
        width: 15vw;
        font-size: 12px;
    }
    .modal-header {
        font-size: 5vw;
    }
    .comm-page-container {
        width: 95%;
    }

    /* Employee */
    .filter-container {
        margin-right: 5% !important;
        margin-bottom: 2% !important;
    }
    /* Message */
    .message-container {
        margin-bottom: 5%;
        width: 94% !important;
        border-radius: 0.5em;
    }
    .message-title{
        font-size: 20px;
    }
    /* Filter */
    .dateFilter{
        min-width: min-content !important;
        margin-bottom: 2%;
    }
    .date-filter-title{
        font-size: 0.8rem !important;
    }
    .export-btn-filter {
        margin-top: 21% !important;
        margin-left: 50% !important;
        margin-bottom: 2%;
        padding: 2px 6px 2px 6px;
    }
}

/* IPhone XR */
@media (min-width: 414px) and (max-width: 420px) and (-webkit-device-pixel-ratio : 2)and (orientation : portrait) {
    .ebr-table-wrapper {
        border-radius: 0.5em;
    }
    .save-button {
        width: 14vw;
    }
    .cancel-button {
        width: 16vw;
    }
    .delete-button {
        width: 16vw;
    }
    .delete-button-employee {
        width: 15vw;
        font-size: 12px;
    }
    .modal-header {
        font-size: 4vw;
    }
    .comm-page-container {
        width: 95%;
    }

    /* Employee */
    .filter-container {
        margin-right: 5% !important;
        margin-bottom: 2% !important;
    }
    /* Message */
    .message-container {
        margin-bottom: 5%;
        width: 94% !important;
        border-radius: 0.5em;
    }
    .message-title{
        font-size: 20px;
    }
    /* Filter */
    .dateFilter{
        min-width: min-content !important;
        margin-bottom: 2%;
    }
    .date-filter-title{
        font-size: 0.8rem !important;
    }
    .export-btn-filter {
        margin-top: 19% !important;
        margin-left: 45% !important;
        margin-bottom: 2%;
        padding: 2px 6px 2px 6px;
    }
}

/* IPhone 11 Pro Max */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) { 
    .ebr-table-wrapper {
        border-radius: 0.5em;
    }
    .save-button {
        width: 14vw;
    }
    .cancel-button {
        width: 16vw;
    }
    .delete-button {
        width: 16vw;
    }
    .delete-button-employee {
        width: 15vw;
        font-size: 12px;
    }
    .modal-header {
        font-size: 4vw;
    }
    .comm-page-container {
        width: 95%;
    }
    /* Employee */
    .filter-container {
        margin-right: 5% !important;
        margin-bottom: 2% !important;
    }
    /* Message */
    .message-container {
        margin-bottom: 5%;
        width: 94% !important;
        border-radius: 0.5em;
    }
    .message-title{
        font-size: 20px;
    }
    /* Filter */
    .dateFilter{
        min-width: min-content !important;
        margin-bottom: 2%;
    }
    .date-filter-title{
        font-size: 0.8rem !important;
    }
    .export-btn-filter {
        margin-top: 19% !important;
        margin-left: 45% !important;
        margin-bottom: 2%;
        padding: 2px 6px 2px 6px;
    }
}

@media (min-width: 400px) and (max-width: 412px){
    .ebr-table-wrapper {
        border-radius: 0.5em;
    }
    .save-button {
        width: 14vw;
    }
    .cancel-button {
        width: 16vw;
    }
    .delete-button {
        width: 16vw;
    }
    .delete-button-employee {
        width: 15vw;
        font-size: 12px;
    }
    .modal-header {
        font-size: 4vw;
    }
    .comm-page-container {
        width: 95%;
    }
    /* Employee */
    .filter-container {
        margin-right: 5% !important;
        margin-bottom: 2% !important;
    }
    /* Message */
    .message-container {
        margin-bottom: 5%;
        width: 94% !important;
        border-radius: 0.5em;
    }
    .message-title{
        font-size: 20px;
    }
    /* Filter */
    .dateFilter{
        min-width: min-content !important;
        margin-bottom: 2%;
    }
    .date-filter-title{
        font-size: 0.8rem !important;
    }
    .export-btn-filter {
        margin-top: 19% !important;
        margin-left: 45% !important;
        margin-bottom: 2%;
        padding: 2px 6px 2px 6px;
    }
}

@media (min-width: 540px) and (max-width: 767px) { 
    .ebr-table-wrapper {
        border-radius: 0.5em;
    }
    .save-button {
        width: 14vw;
    }
    .cancel-button {
        width: 16vw;
    }
    .delete-button {
        width: 16vw;
    }
    .delete-button-employee {
        width: 16vw;
        font-size: 14px;
    }
    .modal-header {
        font-size: 4vw;
    }
    .comm-page-container {
        width: 96%;
    }
    /* Employee */
    .filter-container {
        margin-right: 5% !important;
        margin-bottom: 1% !important;
    }
    /* Message */
    .message-container {
        margin-bottom: 5%;
        width: 97% !important;
        border-radius: 0.5em;
        margin-left: 0.3vw !important;
    }
    .message-title{
        font-size: 20px;
    }
    /* Filter */
    .dateFilter{
        min-width: min-content !important;
        margin-bottom: 2%;
    }
    .date-filter-title{
        font-size: 1rem !important;
    }
    .export-btn-filter {
        margin-top: 15% !important;
        margin-left: 15% !important;
        margin-bottom: 2%;
        padding: 2px 6px 2px 6px;
    }
}

@media (min-width: 768px) and (max-width: 819px) { 
    .menu__btn {
        top: 35px;
    }
    .menu__box{
        width: 35%;
        padding-top: 60px;
    }
    .content{
        width: 95% !important;
    }
    .comm-page-container {
        width: 98%;
    }
    .delete-button-employee {
        width: 13vw;
        font-size: 18px;
    }
    /* Message */
    .message-container {
        margin-bottom: 3%;
    }
    .message-title{
        font-size: 28px;
    }
    /* Filter */
    .dateFilter{
        min-width: min-content !important;
        margin-bottom: 2%;
    }
    .date-filter-title{
        font-size: 1rem !important;
    }
    .export-btn-filter {
        margin-top: 9.5% !important;
        margin-left: 1% !important;
        margin-bottom: 2%;
        padding: 2px 6px 2px 6px;
    }
}

@media (min-width: 820px) and (max-width: 911px){
    .menu__btn {
        top: 35px;
    }
    .menu__box{
        width: 30%;
        padding-top: 60px;
    }
    .content{
        width: 95% !important;
    }
    .comm-page-container {
        width: 98%;
    }
    .delete-button-employee {
        width: 13vw;
        font-size: 18px;
    }
    /* Message */
    .message-container {
        margin-bottom: 3%;
        max-width: 785px !important;
        margin-left: 0.3vw !important;
    }
    .message-title{
        font-size: 28px;
    }
    /* Filter */
    .dateFilter{
        min-width: min-content !important;
        margin-bottom: 2%;
    }
    .date-filter-title{
        font-size: 1rem !important;
    }
    .export-btn-filter {
        margin-top: 9.5% !important;
        margin-left: 1% !important;
        margin-bottom: 2%;
        padding: 2px 6px 2px 6px;
    }
}

@media (min-width: 912px) and (max-width: 1023px) { 
    .menu__btn {
        top: 35px;
    }
    .menu__box{
        width: 30%;
        padding-top: 60px;
    }
    .content{
        width: 95% !important;
    }
    .comm-page-container {
        width: 98%;
    }
    /* Message */
    .message-container {
        margin-bottom: 3%;
        max-width: 875px !important;
        margin-left: 0 !important;
    }
    .message-title{
        font-size: 28px;
    }
    /* Filter */
    .dateFilter{
        min-width: min-content !important;
        margin-bottom: 2%;
    }
    .date-filter-title{
        font-size: 1rem !important;
    }
    .export-btn-filter {
        margin-top: 8% !important;
        margin-left: 1% !important;
        margin-bottom: 2%;
        padding: 2px 6px 2px 6px;
    }
}

@media (min-width:1024px) and (max-width: 1279px){
    .menu__btn {
        top: 20px;
    }
    .menu__box{
        width: 25%;
        padding-top: 25px;
    }
    .content{
        width: 95% !important;
    }
    .comm-page-container {
        width: 98%;
    }
    /* Message */
    .message-container {
        margin-bottom: 3%;
        max-width: 980px !important;
        margin-left: 0.1vw !important;
    }
    /* Filter */
    .dateFilter{
        min-width: min-content !important;
        margin-bottom: 2%;
    }
    .date-filter-title{
        font-size: 1rem !important;
    }
    .export-btn-filter {
        margin-top: 7% !important;
        margin-left: 1% !important;
        margin-bottom: 2%;
        padding: 2px 6px 2px 6px;
    }
}

@media (min-width:1280px) and (max-width: 1290px){
    .dropdown-btn {
        display: flex;
        justify-content: end;
    }
    /* Message */
    .message-container {
        margin-bottom: 3%;
        max-width: 995px !important;
        margin-left: 0.1vw !important;
    }
    /* Filter */
    .dateFilter{
        min-width: min-content !important;
        margin-bottom: 2%;
    }
    .date-filter-title{
        font-size: 1rem !important;
    }
    .export-btn-filter {
        margin-top: 7% !important;
        margin-left: 1% !important;
        margin-bottom: 2%;
        padding: 2px 6px 2px 6px;
    }
}