#menu__toggle {
    opacity: 0;
  }
  #menu__toggle:checked + .menu__btn > span {
    transform: rotate(45deg);
  }
  #menu__toggle:checked + .menu__btn > span::before {
    top: 0;
    transform: rotate(0deg);
  }
  #menu__toggle:checked + .menu__btn > span::after {
    top: 0;
    transform: rotate(90deg);
  }
  #menu__toggle:checked ~ .menu__box {
    left: 0 !important;
  }
  .menu__btn {
    position:relative;
    top: 20px;
    left: 20px;
    width: 26px;
    height: 26px;
    cursor: pointer;
    z-index: 20;
  }
  .menu__btn > span,
  .menu__btn > span::before,
  .menu__btn > span::after {
    display: none;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--primary-color);
    transition-duration: .25s;
  }
  .menu__btn > span::before {
    content: '';
    top: -8px;
    background-color: white;
  }
  .menu__btn > span::after {
    content: '';
    top: 8px;
    background-color: white;
  }
  .menu__box {
    /* display: block; */
    position: fixed;
    top: 0;
    left: -100%;
    margin-top: 1vh;
    margin-left: 1vh;
    padding: 30px 0;
    list-style: none;
    background-color: var(--primary-color);
    transition-duration: .25s;
    display: none; 
    flex-direction: column;
    overflow-y:auto;
    box-shadow: 8px 0 8px -6px rgba(0, 0, 0, 0.3);
    height: 98%;
    width: 20%;
    z-index: 0; 
    -ms-transform: translateY(-50%);
    /* transform: translateY(-50%); */
    border-radius: 25px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }
  .menu__item {
    display: block;
    padding: 12px 24px;
    color: #333;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    transition-duration: .25s;
  }
  .menu__item:hover {
    background-color: #CFD8DC;
  }

.sidenav {
    /* background-color: var(--primary-color); */
        background-color: white;
    box-shadow: 8px 0 8px -6px rgba(0, 0, 0, 0.3);
    overflow-y:scroll;
    height: 98%;
    width: 19%;
    margin-left: 1%;
    margin-right: 1%;
    position: fixed; 
    z-index: 1; 
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: flex; 
    flex-direction: column;
    align-items: left;
    border-radius: 25px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.logo {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -10%;
    justify-content: center;
}

.logoSize {
    max-width: 100%;
}

.content, .content-selected {
    text-transform: uppercase;
    padding: 0.35rem 0.5rem 0.35rem 1.5rem;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    text-decoration: none;
}

.content {
    width: 90%;
        color: var(--text-color);
        fill: white;
        border-radius: 40px 999em 999em 40px
    
}

.content:hover {
    background-color: var(--primary-color);
    cursor: pointer;
    color: white;
}

.content-selected {
    background-color: var(--primary-color);
        cursor: pointer;
        color: white;
}

.content span, .content-selected span {
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 80%;
    line-height: 25px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    margin: 0px;
}

.dropdown p, .dropdown-selected p {
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
        font-size: 80%;
    line-height: 27px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    margin: 0 0 0 4rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    padding-top: .3rem;
    border-radius: 40px 999em 999em 40px;
        text-decoration: none;
        width: 70%;
}

.dropdown {
    color: var(--text-color);
    fill: white;
    z-index: 5 !important;
}

.dropdown p:hover {
    background-color: var(--primary-color);
        color: white;
}

.dropdown-selected p {
    color: white !important;
        background-color: var(--primary-color);
}
.dropdown-selected {
    fill: white;
    background-color: white;
    color: var(--primary-color);
    border-radius: 40px 999em 999em 40px;
    width: 90%;
}

.caret {
    float: right;
    margin-top: -1.6rem;
    margin-right: 1rem;
}

.content-icon {
    float: left;
    /* margin-right: 1rem; */
}


.logout-container {
    position: absolute;
    bottom: 2rem;
    align-self: center;
    text-decoration: none;
    color: black;
    stroke: black;
    fill: black;
    cursor: pointer;
}

.logout-container:hover {
    fill: white;
    color: white;
    stroke: white;
}

.logout-icon {
    float: left;
    margin-right: 1rem;
}

.logout-container span {
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    letter-spacing: 0.02em;
}

.comm-page-container.inactive {
    margin-left: 90px;
  }
  
  .comm-page-container.active {
    margin-left: 320px !important;
  }
  

@media (min-width: 280px) and (max-width: 359px) {
    .menu__btn {
        top: 25px;
    }
    .menu__box{
        width: 90%;
        display: flex;
        z-index: 1;
    }
    .menu__btn > span,
    .menu__btn > span::before,
    .menu__btn > span::after {
      display: block;
    }

    .content{
        width: 95% !important;
    }
    .sidenav{
        display: none;
    }
}

@media (min-width: 360px) and (max-width: 374px) {
    .menu__btn {
        top: 25px;
    }
    .menu__box{
        width: 70%;
        padding-top: 40px;
        display: flex;
        z-index: 1;
    }
    .menu__btn > span,
    .menu__btn > span::before,
    .menu__btn > span::after {
      display: block;
    }
    .content{
        width: 95% !important;
    }
    .sidenav{
        display: none;
    }
}

@media (min-width: 375px) and (max-width: 389px) {
    .menu__btn {
        top: 25px;
    }
    .menu__box{
        width: 65%;
        padding-top: 40px;
        display: flex;
        z-index: 1;
    }
    .menu__btn > span,
    .menu__btn > span::before,
    .menu__btn > span::after {
      display: block;
    }
    .content{
        width: 95% !important;
    }
    .sidenav{
        display: none;
    }
}

/* IPhone 12 Pro */
@media (min-width: 390px) and (max-width: 392px) and (-webkit-device-pixel-ratio : 3)and (orientation : portrait){
    .menu__btn {
        top: 25px;
    }
    .menu__box{
        width: 65%;
        padding-top: 50px;
        display: flex;
        z-index: 1;
    }
    .menu__btn > span,
    .menu__btn > span::before,
    .menu__btn > span::after {
      display: block;
    }
    .content{
        width: 90% !important;
    }
    .sidenav{
        display: none;
    }
}

@media (min-width: 393px) and (max-width: 399px){
    .menu__btn {
        top: 25px;
    }
    .menu__box{
        width: 65%;
        padding-top: 50px;
        display: flex;
        z-index: 1;
    }
    .menu__btn > span,
    .menu__btn > span::before,
    .menu__btn > span::after {
      display: block;
    }
    .content{
        width: 90% !important;
    }
    .sidenav{
        display: none;
    }
}

/* IPhone XR */
@media (min-width: 414px) and (max-width: 420px) and (-webkit-device-pixel-ratio : 2)and (orientation : portrait) {
    .menu__btn {
        top: 25px;
    }
    .menu__box{
        width: 63%;
        padding-top: 50px;
        display: flex;
        z-index: 1;
    }
    .menu__btn > span,
    .menu__btn > span::before,
    .menu__btn > span::after {
      display: block;
    }
    .content{
        width: 90% !important;
    }
    .sidenav{
        display: none;
    }
}

/* IPhone 11 Pro Max */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) { 
    .menu__btn {
        top: 25px;
    }
    .menu__box{
        width: 63%;
        padding-top: 50px;
        display: flex;
        z-index: 1;
    }
    .menu__btn > span,
    .menu__btn > span::before,
    .menu__btn > span::after {
      display: block;
    }
    .content{
        width: 90% !important;
    }
    .sidenav{
        display: none;
    }
}

@media (min-width: 400px) and (max-width: 412px){
    .menu__btn {
        top: 25px;
    }
    .menu__box{
        width: 63%;
        padding-top: 50px;
        display: flex;
        z-index: 1;
    }
    .menu__btn > span,
    .menu__btn > span::before,
    .menu__btn > span::after {
      display: block;
    }
    .content{
        width: 90% !important;
    }
    .sidenav{
        display: none;
    }
}

@media (min-width: 540px) and (max-width: 767px) { 
    .menu__btn {
        top: 25px;
    }
    .menu__box{
        width: 50%;
        padding-top: 50px;
        display: flex;
        z-index: 1;
    }
    .menu__btn > span,
    .menu__btn > span::before,
    .menu__btn > span::after {
      display: block;
    }
    .content{
        width:95% !important;
    }
    .sidenav{
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 819px) { 
    .menu__btn {
        top: 35px;
    }
    .menu__box{
        width: 35%;
        padding-top: 60px;
        display: flex;
        z-index: 1;
    }
    .menu__btn > span,
    .menu__btn > span::before,
    .menu__btn > span::after {
      display: block;
    }
    .content{
        width: 95% !important;
    }
    .sidenav{
        display: none;
    }
}

@media (min-width: 820px) and (max-width: 911px){
    .menu__btn {
        top: 35px;
    }
    .menu__box{
        width: 30%;
        padding-top: 60px;
        display: flex;
        z-index: 1;
    }
    .menu__btn > span,
    .menu__btn > span::before,
    .menu__btn > span::after {
      display: block;
    }
    .content{
        width: 95% !important;
    }
    .sidenav{
        display: none;
    }
}

@media (min-width: 912px) and (max-width: 1023px) { 
    .menu__btn {
        top: 35px;
    }
    .menu__box{
        width: 30%;
        padding-top: 60px;
        display: flex;
        z-index: 1;
    }
    .menu__btn > span,
    .menu__btn > span::before,
    .menu__btn > span::after {
      display: block;
    }
    .content{
        width: 95% !important;
    }
    .sidenav{
        display: none;
    }

}

@media (min-width:1024px) and (max-width: 1279px){
    .menu__btn {
        top: 20px;
    }
    .menu__box{
        width: 25%;
        padding-top: 25px;
        display: flex;
        z-index: 1;
    }
    .menu__btn > span,
    .menu__btn > span::before,
    .menu__btn > span::after {
      display: block;
    }
    .content{
        width: 95% !important;
    }
    .sidenav{
        display: none;
    }
}

@media (min-width:1280px) and (max-width: 1290px){
    .sidenav{
        width: 20%;
    }
    .content{
        width: 95%;
    }
}
