.SSS-wrapper tbody {
    max-height: 52vh;
}

.SSS-wrapper th:nth-child(1),
.SSS-wrapper td:nth-child(1),
.SSS-wrapper th:nth-child(2),
.SSS-wrapper td:nth-child(2) .SSS-wrapper th:nth-child(3),
.SSS-wrapper td:nth-child(3) {
    padding: 0.5%;
    padding-left: 0 !important;
    text-align: left;
}

.SSS-wrapper th:nth-child(4),
.SSS-wrapper td:nth-child(4),
.SSS-wrapper th:nth-child(5),
.SSS-wrapper td:nth-child(5),
.SSS-wrapper th:nth-child(6),
.SSS-wrapper td:nth-child(6),
.SSS-wrapper th:nth-child(7),
.SSS-wrapper td:nth-child(7) {
    padding: 0.5%;
    padding-right: 0 !important;
    text-align: right !important;
}

/* .SSS-wrapper td:nth-child(3),
.SSS-wrapper th:nth-child(3),
.SSS-wrapper td:nth-child(4),
.SSS-wrapper th:nth-child(4) {
    width: 11%;
}


.SSS-wrapper td:nth-child(11),
.SSS-wrapper th:nth-child(11) {
    text-align: center !important;
    padding: 0 !important;
    width: 8%;
}


.SSS-wrapper th,
.SSS-wrapper td {
    font-size: small !important;
}

.printSSS-wrapper .ebr-table-wrapper .ebr-table {
    width: 96vw !important;
    margin-left: -2vw !important;
    overflow-x: hidden;
}

.printSSS-wrapper tbody {
    max-height: 57vh;
}

.printSSS-wrapper th,
.printSSS-wrapper td,
.genSSS-wrapper th,
.genSSS-wrapper td {
    font-size: small !important;
}
.printSSS-wrapper th:nth-child(1),
.printSSS-wrapper td:nth-child(1) {
    text-align: left;
    padding: 0 !important;
    width: 2vw;
}

.printSSS-wrapper th:nth-child(3),
.printSSS-wrapper td:nth-child(3),
.printSSS-wrapper th:nth-child(5),
.printSSS-wrapper td:nth-child(5),
.printSSS-wrapper th:nth-child(7),
.printSSS-wrapper td:nth-child(7),
.printSSS-wrapper th:nth-child(9),
.printSSS-wrapper td:nth-child(9) {
    width: 3vw;
    padding: 0 !important;
}

.printSSS-wrapper th:nth-child(2),
.printSSS-wrapper td:nth-child(2) {
    text-align: left;
    padding: 0 !important;
    width: 6vw;
}

.printSSS-wrapper th:nth-child(2),
.printSSS-wrapper td:nth-child(2) {
    text-align: right;
    padding: 0 !important;
    width: 6vw;
}

.print-SSS {
    text-decoration: none;
}

.print-container {
    height: 100vh;
    width: 100vw;
    background: rgba(255, 255, 255, 0.9);
}

.print-img {
    float: left;
    padding-right: 2%;
    width: 20%;
}

.comm-subheader p {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    padding-top: 8px;
}

/* .gen-SSS-table tr {
    margin-left: 0.5vw !important;
} */


/* .genSSS-wrapper td:nth-child(1),
.genSSS-wrapper th:nth-child(1) {
    width: max-content;
} */

a {
    text-decoration: none;
    color: inherit;
}

/* .genSSS-container {
    padding: 1%;
} */

.yes-border {
    color: var(--primary-color);
    font-size: 1vw;
    padding-right: 25% !important;
    border-color: var(--primary-color) !important;
}
.no-border {
    border-color: white !important;
}
.SSS-form-control {
    border: 1px solid var(--primary-color) !important;
    border-radius: 4px !important;
    width: 10vw !important
}

.filter-btn-wrapper {
    position: relative;
    height: 100%;
}

.filter-btn-wrapper button {
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    width: 80% !important;
}

.SSS-table thead {
    border-top: 2px solid var(--primary-color);
    border-bottom: 2px solid var(--primary-color);
    text-transform: uppercase;
    /* overflow-x: scroll !important; */
}

.SSS-table thead th {
    width: 10vw !important;
}
.SSS-table thead tr,
.SSS-table tbody tr {
    display: block;
    vertical-align: middle;
}

.SSS-table {
    border-bottom: 1px solid var(--fourth-color);
    overflow-x: scroll !important;
    table-layout: auto;
}

.nochange:hover {
    color: inherit !important;
}
.print-page-container {
    background-color: white;
    padding: 8% 5%;
        margin-top: -2vw !important;
    border-radius: 1em;
}

/* .payslip-header {
    font-size: 3vw;
    font-weight: bold;
    color: #054c87;
} */

.blue {
    background-color: #054c87 !important;
}
/* .payslip-logo {
    width: 20%;
}

.payslip-blue-bg {
    background-color: #0667b5;
    color: white;
}

.payslip-total {
    background-color: #9cccf3;
} */
@media screen {
    .printOnly {
        display: none;
    }
}
@media print {
    @page {
        width: 100vw !important;
        size: auto;
        margin: 16mm 16mm 16mm 16mm;
        /* margin-left: -10vw !important; */
        }

    .noprint {
        display: none;
    }
}

.print-button {
    width: 10vw;
    background-color: #0667b5;
    border: none;
    color: white;
    border-radius: 250em;
}
.print-btn-act {
    background-color: var(--primary-color);
    border: none;
    color: white;
    border-radius: 250em;
    width: 5vw !important;
}

.no-margin-top {
    margin-top: -2vw !important;
}
.no-margin-bottom {
    margin-bottom: -1vw !important;
}
.view-SSS {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 90%;
    font-weight: bold;
    stroke: none;
    stroke-width: 0;
    margin-left: 10%;
}
.content-SSS {
    text-align: right;
}
.title-SSS {
    text-align: left;
    padding-left: 0.5%;
}
.leftCol {
    flex: 0 0 30%;
    background-color: none;
    display: right;
    align-items: left;
    padding: 0%;
    padding-left: 1%;
    justify-content: left;
    list-style-type: none;
    margin-bottom: 10px;
}
.midCol {
    flex: 0 0 5%;
    background-color: none;
    display: right;
    align-items: left;
    padding: 0%;
    justify-content: left;
    list-style-type: none;
    margin-bottom: 10px;
}
.rightCol {
    flex: 0 0 25%;
    background-color: none;
    display: flex;
    padding: 0%;
    align-items: top;
    justify-content: left;
    list-style-type: none;
    margin-bottom: 10px;
}