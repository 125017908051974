.schedule-wrapper tbody {
    max-height: 52vh;
}

/* .schedule-wrapper th:nth-child(5) {
    width: 45px;
}

*/
.schedule-wrapper td:nth-child(1),
.schedule-wrapper th:nth-child(1),
.schedule-wrapper td:nth-child(2),
.schedule-wrapper th:nth-child(2),
.schedule-wrapper td:nth-child(3),
.schedule-wrapper th:nth-child(3),
.schedule-wrapper td:nth-child(4),
.schedule-wrapper th:nth-child(4) {
    padding: 0.5%;
        text-align: left !important;
        padding-left: 0 !important;
}