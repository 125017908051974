.leave th:nth-child(6) {
    width: 30px;
}

.payroll th:nth-child(1),
.payroll td:nth-child(1) {
    width: 45px;
}

.gender {
    text-transform: capitalize;
}

.employee-details {
    width: 71vw;
    margin-left: 4vw;
}

.wrapper .ebr-table-wrapper {
    margin: 1em 0 0 0;
    width: fit-content !important;
    overflow-x: hidden;
}

.wrapper tbody {
    height: 26vh;
}

.employee-details tr {
    border-style: hidden;
}

.employee-details th,
.employee-details td {
    font-size: 16px;
    text-align: left;
    line-height: 0.8;
}

.employee-details td {
    font-family: "Source Sans Pro", sans-serif;
    text-align: left;
}

.employee-details th {
    font-family: "Source Sans Pro", sans-serif;
}

.employee-details table {
    margin: 0;
    padding: 0;
}

.employee-details-wrapper {
    width: 100%;
    display: table;
    table-layout: fixed;
}

/* tabs */
.tabs-wrapper tbody {
    max-height: 16vh;
}

.tabs {
    margin-left: 4vw;
}

.tabs .nav-link {
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: black;
    text-transform: uppercase;
}

.tabs .nav-link.active,
.tabs .show>.nav-link {
    border-bottom: 2px solid !important;
    border-top: none;
    border-left: none;
    border-right: none;
    color: black !important;
    background-color: transparent !important;
    border-color: none none black none;
}

.tabs .nav-link:focus,
.tabs .nav-link:hover {
    color: black;
}

.section-container {
    background-color: white;
    border-radius: 10em;
    width: 95%;
    vertical-align: middle;
    color: var(--primary-color);
        box-shadow: 0px 8px 8px -6px rgba(0, 0, 0, 0.3);
}

.secondary-container {
    background-color: white;
    border-radius: 1em;
    width: 95%;
    vertical-align: middle;
    color: var(--primary-color);
    box-shadow: 0px 8px 8px -6px rgba(0, 0, 0, 0.3);
}

.profile-pic-container {
    width: 16vw;
        height: 24vh;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.profile-photo {
    width: 100%;
        height: auto;
        border-radius: 2em;
        /* transform: scale(1.5); */
    }
    
    .bold {
        font-weight: bold;
}