
.page {
    overflow-x: hidden !important;
}

.login-form-container {
    background-color: white;
    border-radius: 2em;
    margin: auto;
    width: 35vw !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    vertical-align: middle;
    padding: 7% 10% !important;
}
.login-container {
    height: 100vh !important;
    --bs-gutter-x: 0 !important;
    overflow-y: auto !important;
    background-color: white !important;
}

/** Left Portion **/
.login-left-side {
    margin: auto !important;
    animation-delay: 3s;
    -webkit-animation-delay: 3s;
    animation: fadein 5s;
    -moz-animation: fadein 5s; /* Firefox */
    -webkit-animation: fadein 5s; /* Safari and Chrome */
    -o-animation: fadein 5s; /* Opera */
}

.login-logo {
    margin-top: 5%;
    width: 50%;
    z-index: 1;
    object-fit: contain;
}

.login-bg {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 30%;

    z-index: 0;
}

/** Right Portion **/
.login-right-side {
    background-color: var(--primary-color);
    border-radius: 5%;
    display: flex;
        flex-direction: column;
        vertical-align: middle;
}

.login-form {
    display: flex;
    flex-direction: column;
    padding-top: 2%;
    padding-bottom: 10%;
        width: 30vw;
                height: 40vh;
    margin: auto;
    background: white;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.5);
    border-radius: 5%;
    align-items: center;
    vertical-align: middle;
}

.welcome-banner {
    font-weight: bold;
    font-size: 60px;
    margin-top: 13%;

}

.instructions-banner {
    font-size: 20px;
    max-width: 75%;
    text-align: left;
    margin-left: 3%;
    margin-bottom: 13%;
}

.login-input {
    border-top-right-radius: 0.5em !important;
        border-bottom-right-radius: 0.5em !important;
    background: #FFFFFF;
    border: 1px solid var(--primary-color);
    box-sizing: border-box;
    border-radius: 0.5em;
        padding: 1%;
        width: 85%;
        }

.Form {
    padding: 0;
    margin-top: 0;
    width: 0%;
}

.Password {
    margin-bottom: 9%;
    /* width: 25vw; */
        width: 120%;
}

.Email {
    margin-bottom: 9%;
    /* width: 25vw; */
    width: 120%;
}
.pass-input-length {
    padding-right: 2rem !important;
}

.Email h1, .Password h1 {
    /* font-family: "Source Sans Pro", sans-serif; */
    font-style: normal;
    font-weight: 550;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.8);
    line-height: 15px;
    letter-spacing: 0.05em;
    margin-bottom: 1%;
}

input {
    display: flex;
    width: 17em;
    line-height: 1.7em;

    border: 2px solid var(--primary-color);
    box-sizing: border-box;
    border-radius: 4px;
    background: none;
    margin: 2%;
}

.Password-img {
    float: right;
    position: absolute;
        transform: translate(-50%, -50%);
        right: 3%;
        top: 50%;
        /* margin-top: -1.5rem; */
}

.btn-wrapper button {
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
    font-size: 1vw;
    color: white;
    background-color: var(--primary-color) !important;
    line-height: 2em;
        border: 1px solid var(--primary-color);
    box-sizing: border-box;
    border-radius: 5%;
    }
    
.login-btn {
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    color: white;
    box-sizing: border-box;
    padding: 1%;
    padding-left: 5%;
    border-radius: 2em;
        margin-bottom: 1em;
    width: 50% !important;
}

.btn-wrapper button:hover {
    color: #FFFFFF;
    /* background: #3068F7; */
    cursor: pointer;
}

.login-sentence {
            color: gray;
            font-size: 150%;
}
  .login-color {
    color: var(--primary-color);
  }
  .login-non-color { 
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: gray;
  }

  .company-name {
    font-family: "Ubuntu", sans-serif;
    font-weight: Bold;
    font-size: 225%;
    line-height: 23px;
    color: var(--primary-color);
    padding-top: 3%;
    top: 58%;
  }

  .company-address {
      top: 63%;
    font-weight: light;
        font-size: 100%;
    line-height: 23px;
    color: var(--primary-color);
    }


/** Media Queries **/
/** Mobile **/
@media (max-width: 1000px){
    /** Left Portion **/
    .login-bg {
        visibility: hidden;
    }
}

/** 4K **/
@media (min-width: 2560px) {
    /** Left Portion **/
    .login-logo {
        width: 30%;
    }
    .login-bg {
        width: 26%;
    }
}

/** Animation **/
@-webkit-keyframes slide {
    100% { margin-left: 5%; }
}

@keyframes slide {
    100% { margin-left: 5%; }
}


@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein { /* Opera */
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
}
