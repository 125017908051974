/* From uiverse.io by @barisdogansutcu */
.download-button {
 position: relative;
 border-width: 0;
 color: white;
 font-size: 15px;
 font-weight: 600;
 border-radius: 4px;
 z-index: 1;
}

.download-button .docs {
 display: flex;
 align-items: center;
 justify-content: space-between;
 gap: 10px;
 min-height: 40px;
 padding: 0 5px;
 border-radius: 4px;
 z-index: 1;
 background: #E8E8E8;
 border: 2px solid #3068F7;
 transition: all .5s cubic-bezier(0.77, 0, 0.175, 1);
 color: #3068F7;

}

/* .download-button:hover {
 box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
} */

.download {
 position: absolute;
 inset: 0;
 display: flex;
 align-items: center;
 justify-content: center;
 max-width: 90%;
 margin: 0 auto;
 z-index: -1;
 border-radius: 4px;
 transform: translateY(0%);
 background-color: #01e056;
 border: solid 1px #01e0572d;
 transition: all .5s cubic-bezier(0.77, 0, 0.175, 1);
}

.download-button:hover .download {
 transform: translateY(80%)
}

.download svg polyline,.download svg line {
 animation: docs 1s infinite;
}

@keyframes docs {
 0% {
  transform: translateY(0%);
 }

 50% {
  transform: translateY(-15%);
 }

 100% {
  transform: translateY(0%);
 }
}
.export-button {
    background-color: var(--primary-color);
    border: none;
    border-radius: 10px;
    color: white;
    padding: 8%;
    border-radius: 500em;
    width: 150% !important;
    margin-right: 100% !important;
    /* border-radius: 999em 999em 999em 999em */
}

.export-text {
    padding-top: 5%;
}

.back-button {
    background-color: transparent;
    border-color: var(--primary-color);
    border-radius: 20px;
    color: #3c3d3c;
    padding: 8%;
    border-radius: 500em;
    width: 200% !important;
    margin-right: 100% !important;
    /* border-radius: 999em 999em 999em 999em */
}

.back-text {
    padding-top: 5%;
}