.tableFooter {
      background-color: var(--secondary-color);
    min-width: 414px;
    width: 100%;
    padding: 8px 15px;
    font-weight: 500;
    text-align: left;
    font-size: 16px;
    color: black;
    overflow-x: hidden !important;
    display: flex !important;
    flex-wrap: wrap;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    /* opacity: 50%; */
  }
  
  .page-count-cont {
    margin-top: 1% !important;
    font-size: 14px;
  }

  .page-show-cont {
    margin-top: 1% !important;
    font-size: 14px;
    display: flex;
    justify-content: end;
  }
  
  .button {
    border: none;
    padding: 7px 14px;
    border-radius: 10px;
    cursor: pointer;
    margin-right: 4px;
    margin-left: 4px;
  }
  
  .activeButton {
    color: white;
    background: var(--primary-color);
  }
  
  .inactiveButton {
    color: black;
    background: #f9f9f9;
  }
  
  .navigateButton {
    color: black;
    background: #f9f9f9;
  }
  
  .rows-input {
    margin-right: 5%;
    margin-left: 1%;
  }
  
  .page-number-input {
    min-width: 14%;
  }
  
  .disable {
    background-color: rgb(190, 190, 190);
  }

.show-btn-container {
  border-radius: 3px;
  background: var(--primary-color);
  border: none;
  color: white;
}

.pg-option {
  padding: 10% !important;
}


.pink-bg {
  background-color: pink;
}

.yellow-bg {
  background-color: yellow;
}

@media (min-width: 280px) and (max-width: 359px) {
  .tableFooter {
    min-width: 150px;
    /* width: 90%; */
  }
  .pages-cont {
    margin-left: 0;
    width: 10% !important;
  }
  .navigateButton {
    width: 19% !important
  }
  .page-count-cont {
    display: none;
  }
  .page-show-cont {
    display: none !important;
  }
} 

  @media only screen and (max-width: 1000px) {
    .register-mobile,
    .user-mobile {
      min-width: 492px;
    }
  
    .search-mobile {
      min-width: 795px;
    }
  
    .company-mobile {
      min-width: 462px;
    }
  }
  
  @media (min-width: 320px) and (max-width: 540px) {
    .tableFooter {
      min-width: 250px;
    }
    .page-count-cont {
      display: none;
      margin-left: 2%;
    }
    .page-show-cont {
      display: none;
      margin-left: 2%;
    }
    .pages-cont {
      margin-left: 0;
    }
  
    .search-wrapper {
      margin-bottom: 5%;
    }
  
    .add-btn {
      width: 50% !important;
      font-size: 12px;
    }
  
    .export-btn {
      width: 50% !important;
      font-size: 10px;
    }
  
    .util-btn-cont.row > * {
      width: 0% !important;
    }
  
    .util-btn-cont {
      margin-bottom: 10%;
    }
  
    .page-number-input {
      width: 70%;
    }
  
    .button {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }
  