.attendance-wrapper tbody {
    max-height: 52vh;
}

.attendance-wrapper th:nth-child(5) {
    width: 30px;
}

.attendance-wrapper td:nth-child(1),
.attendance-wrapper th:nth-child(1) {
    text-align: left;
    padding: 0.5%;
        padding-left: 0 !important;
    width: 20%;
}


.attendance-wrapper td:nth-child(2),
.attendance-wrapper th:nth-child(2) {
    width: 10%;
    padding: 0.5%;
    text-align: left !important;
    padding-left: 0 !important;
}
.attendance-wrapper td:nth-child(3),
.attendance-wrapper th:nth-child(3),
.attendance-wrapper td:nth-child(4),
.attendance-wrapper th:nth-child(4),
.attendance-wrapper td:nth-child(5),
.attendance-wrapper th:nth-child(5) {
    width: 13%;
    padding: 0.5%;
    text-align: left !important;
    padding-left: 0 !important;
}

.attendance-wrapper td:nth-child(6),
.attendance-wrapper th:nth-child(6) {
    padding: 0.5%;
        padding-left: 0 !important;
    text-align: left;
        }
.upload-icon {
    color: var(--text-color);
    width: 10% !important;
}

.upload-file-header {
    color: var(--primary-color);
    font-weight: bolder;
    font-size: 180%;
}
.input-file-btn {
    color: var(--primary-color);
    border: 2px yellow;
}

.input-file-btn-container {
    width: 25%;
    margin: auto;
}

.center-text {
    display: block;
    margin: 0 auto;
    text-align: center;
}
.pointer {
    cursor: pointer !important;
}

.browse-file-container {
    border: 2px solid var(--secondary-color) !important;
    padding: 1.5% 1%;
    border-radius: 0.5em;
}