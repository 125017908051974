.leave-wrapper tbody {
    max-height: 46vh;
}

.leave-wrapper th:nth-child(1),
.leave-wrapper td:nth-child(1) {
    padding: 0.5%;
        padding-left: 0 !important;
    width: 20%;
}

.leave-wrapper th:nth-child(2),
.leave-wrapper td:nth-child(2) {
    padding-left: 0;
    width: 10%;
}

.leave-wrapper th:nth-child(3),
.leave-wrapper td:nth-child(3) {
    padding-left: 0;
    width: 25%;
}

.leave-wrapper th:nth-child(4),
.leave-wrapper td:nth-child(4) {
    padding-left: 0;
    width: 15%;
}

.leave-wrapper th:nth-child(5),
.leave-wrapper td:nth-child(5) {
    padding-left: 0;
}

.leave-wrapper th:nth-child(6),
.leave-wrapper td:nth-child(6) {
    padding-left: 0;
}

.fileName {
    font-size: 80%;
    color: gray;
    font-weight: 5%;
    z-index: 10;
}

.trash-icon {
    border: none !important;
    color: red;
    width: 80%;
}