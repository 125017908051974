.announcement-wrapper tbody {
    max-height: 52vh;
}

.announcement-wrapper td:nth-child(1),
.announcement-wrapper th:nth-child(1),
.announcement-wrapper td:nth-child(2),
.announcement-wrapper th:nth-child(2),
.announcement-wrapper td:nth-child(3),
.announcement-wrapper th:nth-child(3),
.announcement-wrapper td:nth-child(5),
.announcement-wrapper th:nth-child(5) {
    padding: 0.5%;
        text-align: left !important;
        padding-left: 0 !important;
}

.announcement-wrapper td:nth-child(4),
.announcement-wrapper th:nth-child(4) {
    padding: 0.5%;
    text-align: left !important;
    padding-left: 0 !important;
    width: 30%;
}

.announcement-wrapper td:nth-child(6),
.announcement-wrapper th:nth-child(6) {
    padding: 0.5%;
    display: flex;
    justify-content: end;
    padding-left: 0 !important;
}
.header p {
    margin-bottom: 0 !important;
}

/* contracts */
.contracts .nav-link {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 18px;
    color: black;
    text-transform: uppercase;
}

.contracts .nav-link.active, .contracts .show>.nav-link {
    border-bottom: 2px solid !important;
    border-top: none;
    border-left: none;
    border-right: none;
    color: black !important;
    background-color: transparent !important;
    border-color: none none black none;
}

.contracts .nav-link:focus, .contracts .nav-link:hover {
    color: black;
}

/* Filter */
.filter{
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.02em;

    color: #000000;
    margin-left: 1%;
    margin-top: 1%;
}

.cont-filter{
    /* background: #E8E8E8; */
    border: 2px solid #3068F7 !important;
    border-radius: 5px;

    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;

    color: #3068F7;
    margin-top: 5%;
}

.apply-filter{
    background: #3068F7;
    border-radius: 5px;
    border: none;
    padding: 3px;

    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;

    color: #FFFFFF;
    margin-top: 5%;
}

.apply-filter:hover{
    background: white;
    color: #3068F7;
}


.announcement-status{
    position: relative;
    left: 120px;
    top: 15px;
    width: max-content;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 20px;
    padding-left: 20px;
 
    background: #D30000;
    border-radius: 5px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;
}

.announcement-to{
    position: relative;
    left: 231px;
    top: -10px;
    width: max-content;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 20px;
    padding-left: 20px;

    background: #95ACE5;
    border-radius: 5px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;
}

.announcement-time{
    position: relative;
    left: 348px;
    top: -35px;
    width: max-content;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-right: 20px;
    padding-left: 20px;

    border: 2px solid #0A40C2;
    border-radius: 5px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    color: #0A40C2;

}

.message-line{
    position: relative;
    width: 920px;
    height: 0px;
    left: 14px;
    top: -18px;
    border: 3px solid #000000 !important;
}

.message{
    position: relative;
    width: 900px;
    height: 23px;
    left: 2px;
    top: 5px;
    text-align: justify;

    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.02em;

    color: #000000;
}

.announcement-icon{
    font-size: 40px !important;
    position: relative;
    width: 40px;
    height: 40px;
    left: 10px;
    top: 0;
}

.announcement-message-title{
    position: relative;
    width: 300px;
    height: 37px;
    left: 12px;
    top: 7px;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 37px;
    /* identical to box height */

    /* display: flex; */
    align-items: center;
    letter-spacing: 0.02em;
    /* margin-top: 20% !important; */

    color: #000000;
}


/* Message */
.announcement-message-card{
    width: 945px;
    height: 186px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

    margin-top: 2%;
}

.comm-btn-searchs-announcement {
    border: none;
    /* margin-top: 8% !important; */
}

.comm-btns-search-announcement {
    box-sizing: border-box;    
    background-color: #e9ecef;
    border: none !important;
    border-radius: 5px !important;
    /* margin-top: 8% !important; */
}

.comm-btns-search-announcement:hover {
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    transition: all .1s;
    background-color: #e9ecef;
}

.comm-btn-search-announcement {
    box-sizing: border-box;    
    background: none !important; 
    border: 2px solid #3068F7 !important;
    border-radius: 5px !important;
    /* margin-top: 8% !important; */
}

.comm-btn-search-announcement:hover {
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    transition: all .1s;
    background-color: #e9ecef;
}


.comm-announcement-btn {
    box-sizing: border-box;    
    background: #E8E8E8;
    border: 2px solid #3068F7 !important;
    border-radius: 5px !important;
    height: 38%;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    color: #3068F7;
    /* margin-top: 16% !important; */

}

.comm-announcement-btn:hover {
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    transition: all .1s;
}

.comm-announcement-btn:hover {
    background: #60C7EC;
    color: white;
    cursor: pointer;
}

/* Table */
.comm-header-announcement {
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.9vw;
    line-height: 46px;
    letter-spacing: 0.02em;
        color: var(--fourth-color);
    margin-top: .0em;
}

.header-announcement{
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 37px;
    margin-left: 2%;
    margin-top: 2%;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.02em;

    color: #000000;
}

.dropdown-btn-announcement {
    background-color: var(--primary-color);
    border: none;
    border-radius: 10px;
    color: white;
    width: 7vw;
    padding: 1%;
}

.export-button-announcement{
    background-color: var(--primary-color);
    border: none;
    border-radius: 10px;
    color: white;
    padding: 8%;
    border-radius: 500em;
    width: 150% !important;
    margin-left: 17%;
    /* border-radius: 999em 999em 999em 999em */
}

@media (min-width: 280px) and (max-width: 359px) {
    .comm-header-announcement {
        font-size: 8vw;
    }
    .export-button-announcement {
        margin-left: -8% !important;
        width: 198% !important;
    }
    .search-announcement{
        margin-top: -4%;
        margin-left: 2%;
    }
    .dropdown-btn-announcement {
        margin-left: 0 !important;
        width: 15vw;
    }
}

@media (min-width: 360px) and (max-width: 374px) {
    .comm-header-announcement {
        font-size: 2.2vw;
    }
    .export-button-announcement{
        padding: 0 !important;
        width: 170% !important;
        margin-left: 78%;
        margin-top: 10%;
        font-size: 12px;
        text-align: center;
    }
    .search-announcement{
        margin-top: -8%;
    }
    .dropdown-btn-announcement {
        margin-left: 0 !important;
        width: 15vw;
    }
}

@media (min-width: 375px) and (max-width: 389px) {
    .comm-header-announcement {
        font-size: 3vw;
    }
    .export-button-announcement{
        padding: 0 !important;
        width: 175% !important;
        margin-left: 75%;
        margin-top: 10%;
        font-size: 12px;
        text-align: center;
    }
    .search-announcement{
        margin-top: -7%;
    }
    .dropdown-btn-announcement {
        margin-left: 0 !important;
        width: 15vw;
    }
}
/*  */
/* IPhone 12 Pro */
@media (min-width: 390px) and (max-width: 392px) and (-webkit-device-pixel-ratio : 3)and (orientation : portrait){
    .comm-header-announcement {
        font-size: 3.5vw;
    }
    .export-button-announcement{
        padding: 0 !important;
        width: 175% !important;
        margin-left: 75%;
        margin-top: 10%;
        font-size: 12px;
        text-align: center;
    }
    .search-announcement{
        margin-top: -7%;
    }
    .dropdown-btn-announcement {
        margin-left: 0 !important;
        width: 15vw;
    }

}

@media (min-width: 393px) and (max-width: 399px){
    .comm-header-announcement {
        font-size: 3.5vw;
    }
    .export-button-announcement{
        padding: 0 !important;
        width: 175% !important;
        margin-left: 75%;
        margin-top: 10%;
        font-size: 12px;
        text-align: center;
    }
    .search-announcement{
        margin-top: -8%;
    }
    .dropdown-btn-announcement {
        margin-left: 0 !important;
        width: 15vw;
    }

}
/* IPhone XR */
@media (min-width: 414px) and (max-width: 420px) and (-webkit-device-pixel-ratio : 2)and (orientation : portrait) {
    .comm-header-announcement {
        font-size: 3.8vw;
    }
    .export-button-announcement{
        padding: 0 !important;
        width: 175% !important;
        margin-left: 75%;
        margin-top: 10%;
        font-size: 12px;
        text-align: center;
    }
    .search-announcement{
        margin-top: -6%;
    }
    .dropdown-btn-announcement {
        margin-left: 0 !important;
        width: 15vw;
    }

}
/* IPhone 11 Pro Max */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) { 
    .comm-header-announcement {
        font-size: 3.8vw;
    }
    .export-button-announcement{
        padding: 0 !important;
        width: 175% !important;
        margin-left: 75%;
        margin-top: 10%;
        font-size: 12px;
        text-align: center;
    }
    .search-announcement{
        margin-top: -6%;
    }
    .dropdown-btn-announcement {
        margin-left: 0 !important;
        width: 15vw;
    }

}

@media (min-width: 400px) and (max-width: 412px){
    .comm-header-announcement {
        font-size: 3.8vw;
    }
    .export-button-announcement{
        padding: 0 !important;
        width: 175% !important;
        margin-left: 75%;
        margin-top: 10%;
        font-size: 12px;
        text-align: center;
    }
    .search-announcement{
        margin-top: -6%;
    }
    .dropdown-btn-announcement {
        margin-left: 0 !important;
        width: 15vw;
    }

}

@media (min-width: 540px) and (max-width: 767px) { 
    .comm-header-announcement {
        font-size: 4.3vw;
    }
    .export-button-announcement{
        padding: 0 !important;
        width: 120% !important;
        margin-left: 75%;
        margin-top: 10%;
        font-size: 12px;
        text-align: center;
    }
    .search-announcement{
        margin-top: -4%;
    }
    .dropdown-btn-announcement {
        margin-left: 0 !important;
        width: 15vw;
    }

}

@media (min-width: 768px) and (max-width: 819px) { 
    .comm-header-announcement {
        font-size: 4.5vw;
    }
    .export-button-announcement{
        margin-left: 18%;
    }
    .dropdown-btn-announcement{
        margin-left: 78% !important;
        width: 10vw;
    }

}

@media (min-width: 820px) and (max-width: 911px){
    .comm-header-announcement{
        font-size: 5vw;
    }
    .export-button-announcement {
        margin-left: 16%;
    }
    .dropdown-btn-announcement {
        margin-left: 78% !important;
        width: 10vw;
    }

}


@media (min-width: 912px) and (max-width: 1023px) { 
    .comm-header-announcement{
        font-size: 5vw;
    }
    .export-button-announcement{
        margin-left: 16%;
    }
    .dropdown-btn-announcement{
        margin-left: 78% !important;
        width: 10vw;
    }

}

@media (min-width:1024px) and (max-width: 1279px){
    .export-button-announcement {
        margin-left: 16%;
    }
    .dropdown-btn-announcement {
        margin-left: 78% !important;
        width: 10vw;
    }

}

@media (min-width:1280px) and (max-width: 1290px){
    .export-button-announcement{
        margin-left: 16%;
    }

}
