.warning-wrapper tbody {
    max-height: 52vh;
}

.warning-wrapper td:nth-child(1),
.warning-wrapper th:nth-child(1),
.warning-wrapper td:nth-child(3),
.warning-wrapper th:nth-child(3) {
    text-align: left;
    padding: 0.5%;
    padding-left: 0 !important;
    width: 25%;
}
.warning-wrapper td:nth-child(2),
.warning-wrapper th:nth-child(2),
.warning-wrapper td:nth-child(4),
.warning-wrapper th:nth-child(4),
.warning-wrapper td:nth-child(5),
.warning-wrapper th:nth-child(5) {
    text-align: left;
    padding: 0.5%;
    padding-left: 0 !important;
}
.warning-wrapper td:nth-child(6),
.warning-wrapper th:nth-child(6) {
    display: flex;
    justify-content: end;
    padding: 0.5%;
    padding-left: 0 !important;
}
.header p {
    margin-bottom: 0 !important;
}

/* contracts */
.contracts .nav-link {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 18px;
    color: black;
    text-transform: uppercase;
}

.contracts .nav-link.active, .contracts .show>.nav-link {
    border-bottom: 2px solid !important;
    border-top: none;
    border-left: none;
    border-right: none;
    color: black !important;
    background-color: transparent !important;
    border-color: none none black none;
}

.contracts .nav-link:focus, .contracts .nav-link:hover {
    color: black;
}

/* Filter */
.filter-warning{
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.02em;

    color: #000000;
    margin-left: 1%;
    margin-top: 1%;
}

.cont-filter-warning{
    /* background: #E8E8E8; */
    border: 2px solid #3068F7 !important;
    border-radius: 5px;

    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;

    color: #3068F7;
    margin-top: 5%;
}

.apply-filter-warning{
    background: #3068F7;
    border-radius: 5px;
    border: none;
    padding: 3px;

    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;

    color: #FFFFFF;
    margin-top: -5.5%;
}

.apply-filter-warning:hover{
    background: white;
    color: #3068F7;
}

.comm-btn-searchs-warning {
    border: none;
    /* margin-top: 8% !important; */
}

.comm-btns-search-warning {
    box-sizing: border-box;    
    background-color: #e9ecef;
    border: none !important;
    border-radius: 5px !important;
    /* margin-top: 8% !important; */
}

.comm-btns-search-warning:hover {
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    transition: all .1s;
    background-color: #e9ecef;
}

.comm-btn-search-warning {
    box-sizing: border-box;    
    background: none !important; 
    border: 2px solid #3068F7 !important;
    border-radius: 5px !important;
    /* margin-top: 8% !important; */
}

.comm-btn-search-warning:hover {
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    transition: all .1s;
    background-color: #e9ecef;
}

.comm-warning-btn {
    box-sizing: border-box;    
    background: #E8E8E8;
    border: 2px solid #3068F7 !important;
    border-radius: 5px !important;
    height: 38%;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    color: #3068F7;
    /* margin-top: 16% !important; */

}

.comm-warning-btn:hover {
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    transition: all .2s;
}

.comm-warning-btn:hover {
    background: #60C7EC;
    color: white;
    cursor: pointer;
}

/* Table */
.comm-header-warning {
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.9vw;
    line-height: 46px;
    letter-spacing: 0.02em;
    color: #000000;
    margin-top: .5em;
}


/* Create */


.header-warning-letter{
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 37px;
    margin-left: 2%;
    margin-top: 2%;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.02em;

    color: #000000;
}

/* Delete */

.text-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-content: center;
    /* width: fit-content; */
}

.text {
    display: block;
    margin: 0px !important;
    justify-content: center;
    align-content: center;
}

.h1-text{
    justify-content: center;
    align-content: center;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 46px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    text-align: center;
    color: #D30000;
}

.h2-text{
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #545454;
    margin-top: 8%;
}

.h3-text{
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 17px;
    display: flex;
    align-items: center;

    color: #545454;
    margin-top: 2%;
}

.warning {
    width: 15%;
}

.comm-header-warning{
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.9vw;
    line-height: 46px;
    letter-spacing: 0.02em;
    /* color: #000000; */
        color: var(--fourth-color);
        /* margin-left: 1em; */
    margin-top: .0em;
}

.export-button-warning{
    background-color: var(--primary-color);
    border: none;
    border-radius: 10px;
    color: white;
    padding: 8%;
    border-radius: 500em;
    width: 150% !important;
    margin-left: 2%;
    /* border-radius: 999em 999em 999em 999em */
}

.dropdown-btn-warning {
    background-color: var(--primary-color);
    border: none;
    border-radius: 10px;
    color: white;
    width: 7vw;
    padding: 1%;
}

@media (min-width: 280px) and (max-width: 359px) {
    .comm-header-warning {
        font-size: 8vw;
    }
    .export-button-warning{
        margin-left: -10% !important;
        width: 225% !important;
    }
    .search-warning{
        margin-top: -4%;
        margin-left: 2%;
    }
    .dropdown-btn-warning {
        margin-left: 0 !important;
        width: 15vw;
    }
}

@media (min-width: 360px) and (max-width: 374px) {
    .comm-header-warning {
        font-size: 2.2vw;
    }
    .export-button-warning{
        padding: 0 !important;
        width: 250% !important;
        margin-left: 63%;
        margin-top: 10%;
        font-size: 12px;
        text-align: center;
    }
    .search-warning{
        margin-top: -8%;
    }
    .dropdown-btn-warning {
        margin-left: 0 !important;
        width: 15vw;
    }

}

@media (min-width: 375px) and (max-width: 389px) {
    .comm-header-warning {
        font-size: 3vw;
    }
    .export-button-warning{
        padding: 0 !important;
        width: 250% !important;
        margin-left: 43%;
        margin-top: 10%;
        font-size: 12px;
        text-align: center;
    }
    .search-warning{
        margin-top: -8%;
    }
    .dropdown-btn-warning {
        margin-left: 0 !important;
        width: 15vw;
    }

}
/*  */
/* IPhone 12 Pro */
@media (min-width: 390px) and (max-width: 392px) and (-webkit-device-pixel-ratio : 3)and (orientation : portrait){
    .comm-header-warning {
        font-size: 3.5vw;
    }
    .export-button-warning{
        padding: 0 !important;
        width: 210% !important;
        margin-left: 63%;
        margin-top: 10%;
        font-size: 12px;
        text-align: center;
    }
    .search-warning{
        margin-top: -7%;
    }
    .dropdown-btn-warning {
        margin-left: 0 !important;
        width: 15vw;
    }

}

@media (min-width: 393px) and (max-width: 399px){
    .comm-header-warning {
        font-size: 3.5vw;
    }
    .export-button-warning{
        padding: 0 !important;
        width: 210% !important;
        margin-left: 60%;
        margin-top: 10%;
        font-size: 12px;
        text-align: center;
    }
    .search-warning{
        margin-top: -7%;
    }
    .dropdown-btn-warning {
        margin-left: 0 !important;
        width: 15vw;
    }

}
/* IPhone XR */
@media (min-width: 414px) and (max-width: 420px) and (-webkit-device-pixel-ratio : 2)and (orientation : portrait) {
    .comm-header-warning {
        font-size: 3.8vw;
    }
    .export-button-warning{
        padding: 0 !important;
        width: 180% !important;
        margin-left: 68%;
        margin-top: 10%;
        font-size: 12px;
        text-align: center;
    }
    .search-warning{
        margin-top: -6%;
    }
    .dropdown-btn-warning {
        margin-left: 0 !important;
        width: 15vw;
    }

}
/* IPhone 11 Pro Max */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) { 
    .comm-header-warning {
        font-size: 3.8vw;
    }
    .export-button-warning{
        padding: 0 !important;
        width: 180% !important;
        margin-left: 68%;
        margin-top: 10%;
        font-size: 12px;
        text-align: center;
    }
    .search-warning{
        margin-top: -6%;
    }
    .dropdown-btn-warning {
        margin-left: 0 !important;
        width: 15vw;
    }

}

@media (min-width: 400px) and (max-width: 412px){
    .comm-header-warning {
        font-size: 3.8vw;
    }
    .export-button-warning{
        padding: 0 !important;
        width: 180% !important;
        margin-left: 68%;
        margin-top: 10%;
        font-size: 12px;
        text-align: center;
    }
    .search-warning{
        margin-top: -6%;
    }
    .dropdown-btn-warning {
        margin-left: 0 !important;
        width: 15vw;
    }

}

@media (min-width: 540px) and (max-width: 767px) { 
    .comm-header-warning{
        font-size: 4.3vw;
    }
    .export-button-warning{
        padding: 0 !important;
        width: 120% !important;
        margin-left: 76%;
        margin-top: 10%;
        font-size: 12px;
        text-align: center;
    }
    .search-warning{
        margin-top: -4%;
    }
    .dropdown-btn-warning {
        margin-left: 0 !important;
        width: 15vw;
    }

}

@media (min-width: 768px) and (max-width: 819px) { 
    .comm-header-warning{
        font-size: 4.5vw;
    }
    .export-button-warning{
        margin-left: 18%;
    }
    .dropdown-btn-warning{
        margin-left: 44% !important;
        width: 10vw;
    }

}

@media (min-width: 820px) and (max-width: 911px){
    .comm-header-warning{
        font-size: 5vw;
    }
    .export-button-warning{
        margin-left: 18%;
    }
    .dropdown-btn-warning{
        margin-left: 44% !important;
        width: 10vw;
    }

}


@media (min-width: 912px) and (max-width: 1023px) { 
    .comm-header-warning{
        font-size: 5vw;
    }
    .export-button-warning{
        margin-left: 18%;
    }
    .dropdown-btn-warning{
        margin-left: 45% !important;
        width: 10vw;
    }

}

@media (min-width:1024px) and (max-width: 1279px){
    .export-button-warning{
        margin-left: 17%;
    }
    .dropdown-btn-warning{
        margin-left: 43% !important;
        width: 10vw;
    }

}

@media (min-width:1280px) and (max-width: 1290px){
    .export-button-warning{
        margin-left: 17%;
    }
    .dropdown-btn-warning{
        margin-left: 30% !important;
        width: 10vw;
    }

}