.modal-dialog {
    justify-content: center;
    max-width: 900px !important;
}

.form-control, .form-select {
    font-size: 15px !important;
/*    height: 1.5rem;*/
    border: 2px solid var(--primary-color) !important;
    box-sizing: border-box;
    border-radius: 8px !important;
    padding: .1em .1em .1em .4em !important;
}

.form-control:focus-visible, .form-control:focus,
.form-select:focus-visible, .form-select:focus {
    box-shadow: none !important;
    /* outline: 1px solid var(--primary-color) !important; */
}

.modal-content {
    /* background: #E5E5E5 !important; */
        background-color: black;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px !important;
    align-self: center;

    max-height: 91vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.modal-content .btn {
    padding: 2rem 2rem 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.delete-icon {
    float: left;
    font-size: 9rem !important;
    color: black;
}

.delete-modal .header {
    padding-top: 1rem !important;
}

.modal-btn {
    justify-content: right;
    padding: 2rem 2rem 1rem 0;
}

.btn-cancel {
    margin-right: 2rem;
}

.btn-delete {
    margin-right: 0;
    background-color: red !important;
    color: white !important;
    border: none !important;
}

.btn button {
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    color: #008ec2;
    
    padding: 0 1.5rem 0 1.5rem;
    border: 2px solid #008ec2;
    background: none;
    box-sizing: border-box;
    border-radius: 3px;
    text-transform: uppercase;

    /* margin-left: 90% !important; */
    /* margin-right: -2.1% !important; */
}

.btn button:hover {
    color: white;
    background: #15ade4a9;
}

.btn {
    flex-direction: row;
    justify-content: flex-end;
}

.header {
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #000000;
    margin-bottom: .25rem;
    margin-top: .5rem;
    margin-left: 1rem;
}

.subheader, .body {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
}

.body {
    line-height: 25px;
}

.subheader {
    text-transform: uppercase;
    line-height: 5px;
    margin-left: 1rem;
}

.body-head {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #000000;
    padding-top: 1.5em;
}

.no-scroll input[type=number]::-webkit-inner-spin-button, 
.no-scroll input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
.green {
    color: rgb(19, 173, 19) !important;
}