.deductions-wrapper {
    overflow-x: scroll;
}

.deductions-wrapper td:nth-child(1),
.deductions-wrapper th:nth-child(1),
.deductions-wrapper td:nth-child(2),
.deductions-wrapper th:nth-child(2) {
    text-align: left;
    padding: 0.5%;
    padding-left: 0 !important;
    }
    
.deductions-wrapper td:nth-child(3),
.deductions-wrapper th:nth-child(3),
.deductions-wrapper td:nth-child(4),
.deductions-wrapper th:nth-child(4),
.deductions-wrapper td:nth-child(5),
.deductions-wrapper th:nth-child(5),
.deductions-wrapper td:nth-child(6),
.deductions-wrapper th:nth-child(6),
.deductions-wrapper td:nth-child(7),
.deductions-wrapper th:nth-child(7),
.deductions-wrapper td:nth-child(8),
.deductions-wrapper th:nth-child(8),
.deductions-wrapper td:nth-child(9),
.deductions-wrapper th:nth-child(9),
.deductions-wrapper td:nth-child(10),
.deductions-wrapper th:nth-child(10) {
        text-align: right;
        padding: 0.5%;
        padding-left: 0 !important;
}

.deductions-wrapper td:nth-child(11),
.deductions-wrapper th:nth-child(11) {
    text-align: left;
    padding: 0.5%;
    padding-left: 0 !important;
}
.add-deductions {
    text-decoration: none;
    align-self: center;
    color: inherit;
}

.add-deductions:hover {
    color: inherit;
}

.add-deductions-wrapper {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #000000;
}

.deductions-btn {
    background: #FFFFFF;
    border: 2px solid #008ec2;
    box-sizing: border-box;
    border-radius: 5px;
    height: 55%;
    width: fit-content;
    padding-left: 1vw;
    padding-right: 1vw;
    
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.03vw;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #008ec2;
}

.deductions-btn:hover {
    background: #60C7EC;
    color: white;
}

.fetch-data {
    padding: 1rem 2rem 1rem 2rem;
}

.fetch-data th, .fetch-data td {
    padding-right: 2rem;
}

.data {
    padding: 1rem 2rem 1rem 2rem;
}

.add-deductions-wrapper .form-select {
    width: 12rem;
}

/* .border {
    border-left: 1px solid black;
}

.add-deductions-table {
    border-left: 1px solid black;
} */

.add-deductions-table {
    width: max-content;
}

.add-deductions-table .ebr-table-wrapper td {
    /* max-width: 55vw; */
    padding-right: 0 !important;
    padding-top: 2vh;
}

.add-deductions-table tbody {
    font-weight: 300;
    max-height: 45vh;
}

.add-deductions-table .form-control {
    max-width: 8vw;
    margin-left: 6.5vw;
}

.add-deductions-table td:nth-child(1) {
    text-align: left !important;
    padding-left: 3.5vw;
}

.buttons {
    padding-left: 2vh;
}

.comm-header-deduction {
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.9vw;
    line-height: 46px;
    letter-spacing: 0.02em;
    /* color: #000000; */
        color: var(--fourth-color);
        /* margin-left: 1em; */
    margin-top: .0em;
}

.export-button-deduction {
    background-color: var(--primary-color);
    border: none;
    border-radius: 10px;
    color: white;
    padding: 8%;
    border-radius: 500em;
    width: 150% !important;
    margin-left: 38%;
    /* border-radius: 999em 999em 999em 999em */
}

.dropdown-btn-deduction {
    background-color: var(--primary-color);
    border: none;
    border-radius: 10px;
    color: white;
    width: 6vw;
    padding: 1%;
    display: flex;
    justify-content:end !important;

    margin-left: 48% !important;
}

@media (min-width: 280px) and (max-width: 359px) {
    .comm-header-deduction {
        font-size: 8vw;
    }
    .export-button-deduction {
        margin-left: -10% !important;
        width: 225% !important;
    }
    .search-deduction{
        margin-top: 17%;
        margin-left: 2%;
    }
    .dropdown-btn-deduction {
        margin-left: 0 !important;
        width: 25vw;
    }
}

@media (min-width: 360px) and (max-width: 374px) {
    .comm-header-deduction {
        font-size: 5.5vw;
    }
    .export-button-deduction {
        padding: 2px;
        width: 210% !important;
        margin-left: 97%;
        margin-top: 8%;
        font-size: 12px;
        text-align: center;
    }
    .dropdown-btn-deduction {
        margin-left: 0 !important;
        width: 25vw;
    }
}

@media (min-width: 375px) and (max-width: 389px) {
    .comm-header-deduction {
        font-size: 6vw;
    }
    .export-button-deduction {
        padding: 2px;
        width: 210% !important;
        margin-left: 93%;
        margin-top: 8%;
        font-size: 12px;
        text-align: center;
    }
    .dropdown-btn-deduction {
        margin-left: 0 !important;
        width: 25vw;
    }
}

/* IPhone 12 Pro */
@media (min-width: 390px) and (max-width: 392px) and (-webkit-device-pixel-ratio : 3)and (orientation : portrait){
    .comm-header-deduction {
        font-size: 6vw;
    }
    .export-button-deduction {
        padding: 2px;
        width: 180% !important;
        margin-left: 94%;
        margin-top: 8%;
        font-size: 12px;
        text-align: center;
    }
    .dropdown-btn-deduction {
        margin-left: 0 !important;
        width: 25vw;
    }
}

@media (min-width: 393px) and (max-width: 399px){
    .comm-header-deduction {
        font-size: 6vw;
    }
    .export-button-deduction {
        padding: 2px;
        width: 180% !important;
        margin-left: 91%;
        margin-top: 8%;
        font-size: 12px;
        text-align: center;
    }
    .dropdown-btn-deduction {
        margin-left: 0 !important;
        width: 25vw;
    }
}

/* IPhone XR */
@media (min-width: 414px) and (max-width: 420px) and (-webkit-device-pixel-ratio : 2)and (orientation : portrait) {
    .comm-header-deduction {
        font-size: 6vw;
    }
    .export-button-deduction {
        padding: 2px;
        width: 160% !important;
        margin-left: 87%;
        margin-top: 7%;
        font-size: 12px;
        text-align: center;
    }
    .dropdown-btn-deduction {
        margin-left: 0 !important;
        width: 25vw;
    }
}

/* IPhone 11 Pro Max */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) { 
    .comm-header-deduction {
        font-size: 6vw;
    }
    .export-button-deduction {
        padding: 2px;
        width: 160% !important;
        margin-left: 87%;
        margin-top: 7%;
        font-size: 12px;
        text-align: center;
    }
    .dropdown-btn-deduction {
        margin-left: 0 !important;
        width: 25vw;
    }
}

@media (min-width: 400px) and (max-width: 412px){
    .comm-header-deduction {
        font-size: 6vw;
    }
    .export-button-deduction {
        padding: 2px;
        width: 160% !important;
        margin-left: 89%;
        margin-top: 7%;
        font-size: 12px;
        text-align: center;
    }
    .dropdown-btn-deduction {
        margin-left: 0 !important;
        width: 25vw;
    }
}

@media (min-width: 540px) and (max-width: 767px) { 
    .comm-header-deduction {
        font-size: 5vw;
    }
    .export-button-deduction {
        padding: 2px;
        width: 130% !important;
        margin-left: 79%;
        margin-top: 4%;
        font-size: 12px;
        text-align: center;
    }
    .dropdown-btn-deduction {
        margin-left: 0 !important;
        width: 15vw;
    }
}

@media (min-width: 768px) and (max-width: 819px) { 
    .comm-header-deduction {
        font-size: 5vw;
    }
    .export-button-deduction {
        margin-left: 39%;
    }
    .dropdown-btn-deduction {
        margin-left: 30% !important;
        width: 10vw;
    }
}

@media (min-width: 820px) and (max-width: 911px){
    .comm-header-deduction {
        font-size: 5vw;
    }
    .export-button-deduction {
        margin-left: 39%;
    }
    .dropdown-btn-deduction {
        margin-left: 30% !important;
        width: 10vw;
    }
}

@media (min-width: 912px) and (max-width: 1023px) { 
    .comm-header-deduction {
        font-size: 5vw;
    }
    .export-button-deduction {
        margin-left: 38%;
    }
    .dropdown-btn-deduction {
        margin-left: 30% !important;
        width: 10vw;
    }
}

@media (min-width:1024px) and (max-width: 1279px){
    .export-button-deduction {
        margin-left: 38%;
    }
    .dropdown-btn-deduction {
        margin-left: 30% !important;
        width: 10vw;
    }
}

@media (min-width:1280px) and (max-width: 1290px){
    .export-button-deduction {
        margin-left: 38%;
    }
}

