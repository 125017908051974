.holidays-wrapper tbody {
    max-height: 52vh;
}

.holidays-wrapper td:nth-child(1),
.holidays-wrapper th:nth-child(1),
.holidays-wrapper td:nth-child(2),
.holidays-wrapper th:nth-child(2),
.holidays-wrapper td:nth-child(3),
.holidays-wrapper th:nth-child(3),
.holidays-wrapper td:nth-child(4),
.holidays-wrapper th:nth-child(4),
.holidays-wrapper td:nth-child(5),
.holidays-wrapper th:nth-child(5) {
    text-align: left;
    padding: 0.5%;
    padding-left: 0 !important;
}

.form-check-input:checked {
    background-color: var(--primary-color);
}

.Container {
    margin: 0 1rem 0 1rem;
}

.Container input {
    width: 15rem;
}

.comm-header-holidays {
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.9vw;
    line-height: 46px;
    letter-spacing: 0.02em;
    /* color: #000000; */
        color: var(--fourth-color);
        /* margin-left: 1em; */
    margin-top: .0em;
}

.export-button-holidays {
    background-color: var(--primary-color);
    border: none;
    border-radius: 10px;
    color: white;
    padding: 8%;
    border-radius: 500em;
    width: 150% !important;
    margin-left: 25%;
    /* border-radius: 999em 999em 999em 999em */
}

.dropdown-btn-holidays {
    background-color: var(--primary-color);
    border: none;
    border-radius: 10px;
    color: white;
    width: 6vw;
    padding: 1%;
    display: flex;
    justify-content:end !important;

    margin-left: 48% !important;
}

@media (min-width: 280px) and (max-width: 359px) {
    .comm-header-holidays {
        font-size: 8vw;
    }
    .export-button-holidays {
        margin-left: -29% !important;
        width: 210% !important;
    }
    .search-holidays{
        margin-top: 14%;
        margin-left: 2%;
    }
    .name-holiday1{
        font-size: 8px;
    }
    .input-holiday1{
        width: 56% !important;
    }
    .input-holiday2{
        width: 56% !important;
    }
    .dropdown-btn-holidays{
        margin-left: 0 !important;
        width: 25vw;
    }

}

@media (min-width: 360px) and (max-width: 374px) {
    .comm-header-holidays {
        font-size: 6vw;
    }
    .export-button-holidays {
        padding: 2px;
        width: 150% !important;
        margin-left: 73%;
        margin-top: 10%;
        font-size: 12px;
        text-align: center;
    }
    .dropdown-btn-holidays{
        margin-left: 0 !important;
        width: 25vw;
    }
}

@media (min-width: 375px) and (max-width: 389px) {
    .comm-header-holidays {
        font-size: 6vw;
    }
    .export-button-holidays {
        padding: 2px;
        width: 150% !important;
        margin-left: 59%;
        margin-top: 10%;
        font-size: 12px;
        text-align: center;
    }
    .dropdown-btn-holidays{
        margin-left: 0 !important;
        width: 25vw;
    }
}

/* IPhone 12 Pro */
@media (min-width: 390px) and (max-width: 392px) and (-webkit-device-pixel-ratio : 3)and (orientation : portrait){
    .comm-header-holidays {
        font-size: 6vw;
    }
    .export-button-holidays {
        padding: 2px;
        width: 150% !important;
        margin-left: 50%;
        margin-top: 7%;
        font-size: 12px;
        text-align: center;
    }
    .dropdown-btn-holidays{
        margin-left: 0 !important;
        width: 25vw;
    }
}

@media (min-width: 393px) and (max-width: 399px){
    .comm-header-holidays {
        font-size: 6vw;
    }
    .export-button-holidays {
        padding: 2px;
        width: 150% !important;
        margin-left: 50%;
        margin-top: 7%;
        font-size: 12px;
        text-align: center;
    }
    .dropdown-btn-holidays{
        margin-left: 0 !important;
        width: 25vw;
    }
}

/* IPhone XR */
@media (min-width: 414px) and (max-width: 420px) and (-webkit-device-pixel-ratio : 2)and (orientation : portrait) {
    .comm-header-holidays {
        font-size: 6vw;
    }
    .export-button-holidays {
        padding: 2px;
        width: 150% !important;
        margin-left: 41%;
        margin-top: 6%;
        font-size: 12px;
        text-align: center;
    }
    .dropdown-btn-holidays{
        margin-left: 0 !important;
        width: 25vw;
    }
}

/* IPhone 11 Pro Max */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) { 
    .comm-header-holidays {
        font-size: 6vw;
    }
    .export-button-holidays {
        padding: 2px;
        width: 150% !important;
        margin-left: 41%;
        margin-top: 6%;
        font-size: 12px;
        text-align: center;
    }
    .dropdown-btn-holidays{
        margin-left: 0 !important;
        width: 25vw;
    }
}

@media (min-width: 400px) and (max-width: 412px){
    .comm-header-holidays {
        font-size: 6vw;
    }
    .export-button-holidays {
        padding: 2px;
        width: 150% !important;
        margin-left: 41%;
        margin-top: 6%;
        font-size: 12px;
        text-align: center;
    }
    .dropdown-btn-holidays{
        margin-left: 0 !important;
        width: 25vw;
    }
}

@media (min-width: 540px) and (max-width: 767px) { 
    .comm-header-holidays {
        font-size: 6vw;
    }
    .export-button-holidays {
        padding: 2px;
        width: 125% !important;
        margin-left: 68%;
        margin-top: 7%;
        font-size: 12px;
        text-align: center;
    }
    .dropdown-btn-holidays {
        margin-left: 0 !important;
        width: 15vw;
    }
}

@media (min-width: 768px) and (max-width: 819px) { 
    .comm-header-holidays {
        font-size: 5vw;
    }
    .export-button-holidays {
        margin-left: 26%;
    }
    .dropdown-btn-holidays{
        margin-left: 30% !important;
        width: 10vw;
    }
}

@media (min-width: 820px) and (max-width: 911px){
    .comm-header-holidays{
        font-size: 5vw;
    }
    .export-button-holidays {
        margin-left: 26%;
    }
    .dropdown-btn-holidays{
        margin-left: 30% !important;
        width: 10vw;
    }
}

@media (min-width: 912px) and (max-width: 1023px) { 
    .comm-header-holidays{
        font-size: 5vw;
    }
    .export-button-holidays {
        margin-left: 26%;
    }
    .dropdown-btn-holidays{
        margin-left: 30% !important;
        width: 10vw;
    }
}

@media (min-width:1024px) and (max-width: 1279px){
    .export-button-holidays {
        margin-left: 26%;
    }
    .dropdown-btn-holidays{
        margin-left: 30% !important;
        width: 10vw;
    }
}

@media (min-width:1280px) and (max-width: 1290px){
    .export-button-holidays {
        margin-left: 26%;
    }
}